@charset "utf-8";

@mixin flex($wrap:nowrap,$justify:flex-start){
  display: flex;
  justify-content: $justify;
  flex-wrap: $wrap;
  @include mq(sp) {
    display: block;
    width: auto;
  }
}
@mixin flex-sp($wrap:nowrap,$justify:flex-start){
  display: flex;
  justify-content: $justify;
  flex-wrap: $wrap;
}