@mixin opacity($opacity) {
	opacity: $opacity;
	filter: alpha(opacity=($opacity * 100));
}

@mixin clearfix() {
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin box-sizing($type) {
	-webkit-box-sizing:$type;
	-moz-box-sizing:$type;
	box-sizing:$type;
}

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

@mixin base($width:1096,$pc:20,$sp:8vw) {
  max-width: $width * 1px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $pc * 1px;
  padding-right: $pc * 1px;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: $sp;
    padding-right: $sp;
  }
}

@mixin arrow {
  position: relative;
  &::before {
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-top: 1px solid $main;
    border-right: 1px solid $main;
    transform: rotate(45deg) translateY(-50%);
    @include mq(sp) {
      width: 6px;
      height: 6px;
    }
  }
}
