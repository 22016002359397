@font-face {
  font-family: FontAwesome;
  src: url(../fonts/fontawesome-webfont.eot?v=4.6.3);
  src: url(../fonts/fontawesome-webfont.eot?#iefix&v=4.6.3) format("embedded-opentype"), url(../fonts/fontawesome-webfont.woff2?v=4.6.3) format("woff2"), url(../fonts/fontawesome-webfont.woff?v=4.6.3) format("woff"), url(../fonts/fontawesome-webfont.ttf?v=4.6.3) format("truetype"), url(../fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular) format("svg");
  font-weight: 400;
  font-style: normal;
}

[data-bgb=title] h2 {
  position: relative;
  margin: 2em 0;
  padding: .3em .5em;
  background: none;
  color: $main;
  font-family: $font-katura;
  font-size: 35px;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;
  overflow: hidden;
  @include mq(sp) {
    font-size: 20px;
  }
}

[data-bgb=title] h2:first-child {
}

[data-bgb=title] h2:before {
  position: absolute;
  bottom: -.2em;
  left: 50%;
  content: "";
  display: block;
  width: 0;
  height: 0;
  margin: .6em auto;
  border-style: solid;
  border-width: 8px 17.5px 0 17.5px;
  border-color: $main transparent transparent transparent;
  transform: translateX(-50%);
  @include mq(sp) {
    border-width: 5px 10px 0 10px;
  }
}

[data-bgb=title] h2:after {
  content: "";
  display: block;
  width: 4em;
  height: 2px;
  margin: .6em auto;
  background: $main;
  margin: .6em auto;
}

[data-bgb=title1] h2, [data-bgb=wysiwyg] h2, [data-bgb=wysiwyg2] h2,
[data-bgb=youtube2-wysiwyg] h2 {
  margin: 2em 0;
  padding: .5em 0;
  border-top: 1px solid $main;
  border-bottom: 1px solid $main;
  color: $main;
  font-family: $font-katura;
  font-weight: 400;
  font-size: 26px;
  line-height: 1.2;
  text-align: center;
  @include mq(sp) {
    margin: 1.6em 0;
    padding: 1em 0;
    font-size: 16px;
  }
  a {
    display: block;
    text-decoration: none;
    &:hover {
      background: $sky;
    }
  }
}

[data-bgb=title1] h2:first-child, [data-bgb=wysiwyg] h2:first-child, [data-bgb=wysiwyg2] h2:first-child,
[data-bgb=youtube2-wysiwyg] h2:first-child {
  margin-top: 0;
}

[data-bgb=title1a] h2 {
  margin: 2em 0;
  padding: .5em 1.4em;
  background: $main url(/img/burger/bg_ttl_h3.png) right top no-repeat;
  color: #fff;
  font-family: $font-katura;
  font-size: 23px;
  font-weight: 400;
  line-height: 1.2;
  overflow: hidden;
  @include mq(sp) {
    margin: 1.6em 0;
    background-size: 665px 77px;
    font-size: 16px;
  }
  a {
    display: block;
    color: #fff;
    text-decoration: none;
  }
}
[data-bgb=title1a] h2:first-child {
  margin-top: 0;
}

[data-bgb=title2] h3, [data-bgb=wysiwyg] h3, [data-bgb=youtube2-wysiwyg] h3 {
  margin: 2em 0;
  padding: .5em 1.4em;
  background: $main url(/img/burger/bg_ttl_h3.png) right top no-repeat;
  color: #fff;
  font-family: $font-katura;
  font-size: 23px;
  font-weight: 400;
  line-height: 1.2;
  overflow: hidden;
  @include mq(sp) {
    margin: 1.6em 0;
    background-size: 665px 77px;
    font-size: 16px;
  }
  a {
    display: block;
    color: #fff;
    text-decoration: none;
  }
}

[data-bgb=title2a] h3 {
  margin-bottom: 20px;
  color: $main;
  font-family: $font-katura;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  @include mq(sp) {
    margin-bottom: 10px;
    font-size: 19px;
  }
}

[data-bgb] h3:first-child {
  margin-top: 0;
}



[data-bgb] h4 {
  position: relative;
  margin: 1em 0;
  padding: .3em .2em .5em 1.4em;
  border-bottom: 2px solid #CFCFCF;
  color: $main;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  @include mq(sp) {
    font-size: 15px;
  }
}

[data-bgb] h4:first-child {
  margin-top: 0;
}

[data-bgb] h4:before {
  position: absolute;
  top: 13px;
  left: 0;
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  background: $main;
  transform: rotate(45deg);
  transform-origin: 50% 50% 0;
  @include mq(sp) {
    top: 9px;
  }
}

[data-bgb] h4:after {
  position: absolute;
  bottom: -2px;
  left: 0;
  content: "";
  width: 20%;
  height: 2px;
  background-color: $main;
}

[data-bgb] h5 {
  font-size:1.2em;
  font-weight:700;
  line-height:1.2;
  margin:1em 0;
  padding:.3em 0;
  border-bottom:1px dashed #999;
  overflow:hidden
}

[data-bgb] h5:first-child {
  margin-top: 0;
}

[data-bgb] h6 {
  margin: 1em 0 .8em;
  color: $main;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  @include mq(sp) {
    margin: 0.6em 0;
    font-size: 16px;
  }
}

[data-bgb] h6:first-child {
  margin-top: 0
}

[data-bgb] p {
  margin: 1em 0
}

[data-bgb] p.title-01 {
  margin: 2em 0;
  padding: .5em 0;
  border-top: 1px solid $main;
  border-bottom: 1px solid $main;
  color: $main;
  font-family: $font-katura;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;
  @include mq(sp) {
    margin: 0.6em 0 1.6em;
    padding: .75em 0;
    font-size: 16px;
  }
}
[data-bgb].title-01 p {
  margin: 2em 0;
  padding: .5em 0;
  border-top: 1px solid $main;
  border-bottom: 1px solid $main;
  color: $main;
  font-family: $font-katura;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;
  @include mq(sp) {
    margin: 0.6em 0 1.6em;
    padding: .75em 0;
    font-size: 16px;
  }
}
[data-bgb] p.title-02 {
  margin: 2em 0 .8em;
  color: $main;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  @include mq(sp) {
    margin: 0.6em 0;
    font-size: 16px;
  }
}
[data-bgb].title-02 p {
  margin: 2em 0 .8em;
  color: $main;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  @include mq(sp) {
    margin: 0.6em 0;
    font-size: 16px;
  }
}

[data-bgb] p:first-child {
  margin-top: 0
}
[data-bgb] p:last-child {
  margin-bottom: 0
}

[data-bgb] li {
  list-style: inherit;
}

[data-bgb] li:first-child {
  margin-top: 0
}

[data-bgb] ul {
  margin: 1em 0
}

[data-bgb] ul:first-child {
  margin-top: 0
}

[data-bgb] ul ul {
  margin: 0 0 0 1.8em
}

[data-bgb] ul ul:first-child {
  margin-top: 0
}

[data-bgb] ul li {
  list-style-position: inside;
  list-style-type: disc;
  margin: 0 0 .2em 2.5em;
  text-indent: -1.5em;
}

[data-bgb] ol {
  margin: 1em 0 1em 1.3em;
}

[data-bgb] ol:first-child {
  margin-top: 0
}

[data-bgb] ol ol {
  margin: 0 0 0 2em;
}

[data-bgb] ol ol:first-child {
  margin-top: 0
}

[data-bgb] ol li {
  margin: 0 0 .2em -1.3em;
  text-indent: -1.3em;
  list-style-position: inside;
  list-style-type: decimal;
}

[data-bgb] blockquote {
  position: relative;
  margin: 1em 0;
  padding: 1px 2.5em;
  border-radius: 5px;
  background: none;
  border: 2px solid #ccc;
  overflow: hidden;
  color: #888;
  font-size: .9em
}

[data-bgb] blockquote:first-child {
  margin-top: 0
}

[data-bgb] blockquote:before {
  content: "“";
  top: .3em;
  left: .25em
}

[data-bgb] blockquote:after, [data-bgb] blockquote:before {
  display: block;
  font-family: serif;
  position: absolute;
  z-index: 0;
  color: #ccc;
  font-size: 3em
}

[data-bgb] blockquote:after {
  content: "”";
  bottom: -.3em;
  right: .25em
}

[data-bgb] table {
  min-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
  margin: 1em 0;
}

@media screen and (max-width:768px) {
  [data-bgb] table:not(.table-scroll) {
    width: 100%;
    display: block
  }
}

[data-bgb] table:first-child {
  margin-top: 0
}

[data-bgb] table caption {
  /* margin-bottom: 5px;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  &::before {
    content: "■ ";
    color: $main;
  }
  @include mq(sp) {
    font-size: 16px;
  } */
}

@media screen and (max-width:768px) {
  [data-bgb] table:not(.table-scroll) caption, [data-bgb] table:not(.table-scroll) tbody, [data-bgb] table:not(.table-scroll) tfoot {
    display: block
  }
}

@media screen and (max-width:768px) {
  [data-bgb] table:not(.table-scroll) tr {
    display: block;
    margin-top: -1px
  }
}

[data-bgb] table tr td {
  border-top: 1px solid $main;
  border-bottom: 1px solid $main;
  background: #fff;
}

[data-bgb] table tr td, [data-bgb] table tr th {
  text-align: left;
  padding: 1em 2em;
  border-top: 1px solid $main;
  border-bottom: 1px solid $main;
  @include mq(sp) {
    padding: .8em 1em;
    line-height: 1.5;
  }
}

@media screen and (max-width:768px) {
  [data-bgb] table:not(.table-scroll) tr td, [data-bgb] table:not(.table-scroll) tr th {
    display: block;
    width: auto;
  }
}

[data-bgb] table tr th {
  font-weight: 500;
  background: $sky;
  color: $main;
}

@media screen and (max-width:768px) {
  [data-bgb] table:not(.table-scroll) tr th {
    border-bottom: none
  }
}

[data-bgb] table tr td {
  font-weight: 400
}

[data-bgb] table tr th,
[data-bgb] table tr td {
  border-right: 1px solid #f2f2f2;
  border-left: 1px solid #f2f2f2;
}

[data-bgb] strong {
  font-weight: 700;
  font-style: normal;
  text-decoration: none
}

[data-bgb] u {
  text-decoration: underline
}

[data-bgb] s, [data-bgb] u {
  font-weight: 400;
  font-style: normal
}

[data-bgb] s {
  text-decoration: line-through
}

[data-bgb] sub {
  vertical-align: sub
}

[data-bgb] sub, [data-bgb] sup {
  font-size: .8em;
  font-weight: 400;
  font-style: normal;
  text-decoration: none
}

[data-bgb] sup {
  vertical-align: super
}

[data-bgb] hr {
  width: 100%;
  height: 1px;
  color: $main;
  background: none;
  border-top: 1px dashed $main;
  margin: 50px auto;
}

[data-bgb] hr:first-child {
  margin-top: 0
}

.bgt-box__caption {
  margin-bottom: -20px;
  padding: 15px 0;
  background: none;
  color: $black;
  font-family: $font-katura;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  @include mq(sp) {
    font-size: 14px;
  }
}

.bgt-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  display: inline-block;
  min-width: 280px;
  padding: 16px 45px 16px 20px;
  background: $sky;
  border: 1px solid $sky;
  border-radius: 50px;
  color: $main;
  font-family: $font-katura;
  font-size: 20px;
  line-height: 1.2;
  text-decoration: none;
  vertical-align: middle;
  text-decoration: none !important;
  transition: .5s;
  @include mq(sp) {
    width: 100%;
    padding: 12px 40px 12px 20px;
    font-size: 15px;
  }
  &::after {
    position: absolute;
    right: 25px;
    top: 50%;
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 10px;
    border-color: transparent transparent transparent $main;
    transform: translateY(-50%);
    transition: .5s;
    @include mq(sp) {
      right: 20px;
      border-width: 5px 0 5px 8px;
    }
  }
}

[data-bgb=button] [data-bgt=button],
[data-bgb=button2] [data-bgt=button],
[data-bgb=button3] [data-bgt=button] {
  @include mq(sp) {
    display: block;
  }
}

.bgt-btn:active, .bgt-btn:focus {
  -webkit-box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
  box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff
}

.bgt-btn--link, [data-bgt-button-kind=link] .bgt-btn {
  color: $main;
  border-color: $sky;
  background: $sky;
}

.bgt-btn--link:active, .bgt-btn--link:focus, .bgt-btn--link:hover, [data-bgt-button-kind=link] .bgt-btn:active, [data-bgt-button-kind=link] .bgt-btn:focus, [data-bgt-button-kind=link] .bgt-btn:hover {
  @include mq(pc) {
    background: #FFF9BD;
    border-color: #FFF9BD;
  }
}

.bgt-btn--em, [data-bgt-button-kind=em] .bgt-btn {
  color: $main;
  border-color: #FFEC00;
  background: #FFEC00;
}

.bgt-btn--em:active, .bgt-btn--em:focus, .bgt-btn--em:hover, [data-bgt-button-kind=em] .bgt-btn:active, [data-bgt-button-kind=em] .bgt-btn:focus, [data-bgt-button-kind=em] .bgt-btn:hover {
    @include mq(pc) {
    border-color: $main;
    background: $main;
    color: #fff;
  }
  &::after {
    @include mq(pc) {
      border-color: transparent transparent transparent #fff;
    }
  }
}

.bgt-btn--wh, [data-bgt-button-kind=wh] .bgt-btn {
  color: $main;
  border-color: $main;
  background: #fff;
}

.bgt-btn--wh:active, .bgt-btn--wh:focus, .bgt-btn--wh:hover, [data-bgt-button-kind=wh] .bgt-btn:active, [data-bgt-button-kind=wh] .bgt-btn:focus, [data-bgt-button-kind=wh] .bgt-btn:hover {
  @include mq(pc) {
    border-color: #fff;
    background: linear-gradient(-90deg, #cde2f7 0%, #e4f9fa 100%);
    color: $main;
  }
  &::after {
    @include mq(pc) {
      border-color: transparent transparent transparent $main;
    }
  }
}

.bgt-btn--bl, [data-bgt-button-kind=bl] .bgt-btn {
  color: #fff;
  border-color: $main;
  background: linear-gradient(-90deg, #2d3a80 0%, #375dd0 100%);
  &::after {
    border-color: transparent transparent transparent #fff;
  }
}

.bgt-btn--bl:active, .bgt-btn--bl:focus, .bgt-btn--bl:hover, [data-bgt-button-kind=bl] .bgt-btn:active, [data-bgt-button-kind=bl] .bgt-btn:focus, [data-bgt-button-kind=bl] .bgt-btn:hover {
  @include mq(pc) {
    border-color: $main;
    background: #fff;
    color: $main;
  }
  &::after {
    @include mq(pc) {
      border-color: transparent transparent transparent $main;
    }
  }
}

.bgt-btn--external, [data-bgt-button-kind=external] .bgt-btn {
  color: $main;
  border-color: $main;
  background: #fff;
  transition: .5s;
}

.bgt-btn--external:active, .bgt-btn--external:focus, .bgt-btn--external:hover, [data-bgt-button-kind=external] .bgt-btn:active, [data-bgt-button-kind=external] .bgt-btn:focus, [data-bgt-button-kind=external] .bgt-btn:hover {
  @include mq(pc) {
    border-color: $sky;
    background: linear-gradient(-90deg, #cde2f7 0%, #e4f9fa 100%);
  }
}

.bgt-btn--external:after, [data-bgt-button-kind=external] .bgt-btn:after {
  position: static;
  display: inline-block;
  font-family: FontAwesome, serif;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f08e";
  font-size: 1em;
  color: $main;
  vertical-align: middle;
  border: none;
  transform: translateY(-13px);
}

.bgt-btn--back, [data-bgt-button-kind=back] .bgt-btn {
  color: #333;
  border-color: #ddd;
  background: #ddd
}

.bgt-btn--back:active, .bgt-btn--back:focus, .bgt-btn--back:hover, [data-bgt-button-kind=back] .bgt-btn:active, [data-bgt-button-kind=back] .bgt-btn:focus, [data-bgt-button-kind=back] .bgt-btn:hover {
  @include mq(pc) {
    background: #e9e9e9;
  }
}

.bgt-btn--back span, [data-bgt-button-kind=back] .bgt-btn span {
  display: inline-block;
  vertical-align: middle
}

.bgt-btn--back:before, [data-bgt-button-kind=back] .bgt-btn:before {
  font-family: FontAwesome, serif;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f053";
  font-size: 1em;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle
}


//受験生情報サイトボタン（青）
.bgt-btn--jb, [data-bgt-button-kind=jb] .bgt-btn {
  border-width: 3px;
  border-color: $j-blue;
  background: $j-blue;
  color: #fff;
  font-family: $font-notosans;
  font-weight: 700;
  &::after {
    border-color: transparent transparent transparent #fff;
  }
}
.bgt-btn--jb:active, .bgt-btn--jb:focus, .bgt-btn--jb:hover, [data-bgt-button-kind=jb] .bgt-btn:active, [data-bgt-button-kind=jb] .bgt-btn:focus, [data-bgt-button-kind=jb] .bgt-btn:hover {
  @include mq(pc) {
    background: $j-blue2;
    color: $j-blue;
  }
  &::after {
    @include mq(pc) {
      border-color: transparent transparent transparent $j-blue;
    }
  }
}
//受験生情報サイトボタン（赤）
.bgt-btn--jr, [data-bgt-button-kind=jr] .bgt-btn {
  border-width: 3px;
  border-color: $j-red;
  background: $j-red;
  color: #fff;
  font-family: $font-notosans;
  font-weight: 700;
  &::after {
    border-color: transparent transparent transparent #fff;
  }
}
.bgt-btn--jr:active, .bgt-btn--jr:focus, .bgt-btn--jr:hover, [data-bgt-button-kind=jr] .bgt-btn:active, [data-bgt-button-kind=jr] .bgt-btn:focus, [data-bgt-button-kind=jr] .bgt-btn:hover {
  @include mq(pc) {
    background: $j-red2;
    color: $j-red;
  }
  &::after {
    @include mq(pc) {
      border-color: transparent transparent transparent $j-red;
    }
  }
}
//受験生情報サイトボタン（黄）
.bgt-btn--jy, [data-bgt-button-kind=jy] .bgt-btn {
  border-width: 3px;
  border-color: $j-yellow;
  background: $j-yellow;
  color: #fff;
  font-family: $font-notosans;
  font-weight: 700;
  &::after {
    border-color: transparent transparent transparent #fff;
  }
}
.bgt-btn--jy:active, .bgt-btn--jy:focus, .bgt-btn--jy:hover, [data-bgt-button-kind=jy] .bgt-btn:active, [data-bgt-button-kind=jy] .bgt-btn:focus, [data-bgt-button-kind=jy] .bgt-btn:hover {
  @include mq(pc) {
    background: $j-yellow2;
    color: $j-yellow;
  }
  &::after {
    @include mq(pc) {
      border-color: transparent transparent transparent $j-yellow;
    }
  }
}

.bgt-hr {
  margin: 0 auto
}

[data-bgt-hr-kind=dashed] .bgt-hr {
  height: 0;
  background: none;
  border: none;
  border-bottom: 2px dashed
}

.bgt-hr--bold, [data-bgt-hr-kind=bold] .bgt-hr {
  height: 5px
}

.bgt-hr--narrow, [data-bgt-hr-kind=narrow] .bgt-hr {
  height: 1px
}

.bgt-hr--short, [data-bgt-hr-kind=short] .bgt-hr {
  width: 60%;
  height: 1px
}

.bgt-download-file__link {
  color: $main;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding: .7em 1em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  text-decoration: underline;
  @include mq(sp) {
    font-size: 14px;
  }
  .bgt-link__size {
    display: none;
  }
  .bgt-link__name {
    @include mq(sp) {
    }
  }
  &-box {
    margin: 16px 0 8px;
    border: 1px solid #ccc;
    text-decoration: none !important;
    &:hover {
      background: $sky;
    }
  }
}

.bgt-download-file__link:hover {
  color: #F07700;
}

.bgt-download-file__link:active, .bgt-download-file__link:focus {
  -webkit-box-shadow: 0 0 5px 1px #0570c7;
  box-shadow: 0 0 5px 1px #0570c7;
  outline: none
}

.bgt-link__size {
  color: grey;
  font-size: .8em;
  font-weight: 400
}

@media screen and (max-width:768px) {
  .bgt-google-maps-link {
    margin-top: 10px;
    padding: 1em;
    text-decoration: none;
    color: #333;
    border: 1px solid #999;
    border-radius: 3px;
    text-align: center
  }
  .bgt-google-maps-link span {
    display: inline-block;
    vertical-align: middle
  }
  .bgt-google-maps-link:after {
    font-family: FontAwesome, serif;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f08e";
    font-size: 1em;
    vertical-align: middle
  }
}

// 受験生情報サイト用パーツ
[data-bgb=jyuken-title] {
  text-align: center;
  h2 {
    position:relative;
    display: inline-block;
    min-width: 500px;
    margin: 10px -20px;
    padding: 15px;
    border: solid 3px $j-blue;
    border-radius: 3px;
    background: #fff;
    color: $j-blue;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    @include mq(sp) {
      min-width: auto;
      padding: 10px 15px;
      font-size: 17px;
    }
  }
}
[data-bgb=jyuken-title] h2::after, [data-bgb=jyuken-title] h2::before {
  content: '';
  position: absolute;
  bottom: -7px;
  background: $j-blue;
  border: solid 2px $j-blue;
  width: 25px;
  height: 20px;
  z-index: -1;
}
[data-bgb=jyuken-title] h2::after {
     left: 2px;
     transform: rotate(120deg);
}
[data-bgb=jyuken-title] h2::before {
    right: 2px;
    transform: rotate(60deg);
}
[data-bgb=jyuken-title].jyuken-red {
  h2 {
    border-color: $j-red;
    color: $j-red;
    &::before, &::after {
      border-color: $j-red;
      background-color: $j-red;
    }
  }
}
[data-bgb=jyuken-title].jyuken-yellow {
  h2 {
    border-color: $j-yellow;
    color: $j-yellow;
    &::before, &::after {
      border-color: $j-yellow;
      background-color: $j-yellow;
    }
  }
}

[data-bgb=jyuken-title2] {
  text-align: center;
  h2 {
    padding: 8px;
    border-top: 2px solid $j-blue;
    border-bottom: 2px solid $j-blue;
    color: $j-blue;
    font-size: 24px;
    font-weight: 700;
    @include mq(sp) {
      padding: 5px;
      font-size: 15px;
    }
  }
}
[data-bgb=jyuken-title2].jyuken-red {
  h2 {
    border-color: $j-red;
    color: $j-red;
  }
}
[data-bgb=jyuken-title2].jyuken-yellow {
  h2 {
    border-color: $j-yellow;
    color: $j-yellow;
  }
}

[data-bgb=jyuken-title3] {
  h3 {
    padding: 10px 25px;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 4px, rgba(32, 152, 206, .1) 4px, rgba(32, 152, 206, .1) 10px );
    color: $j-blue;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.5;
    @include mq(sp) {
      padding: 8px 20px;
      background-image: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(32, 152, 206, .1) 3px, rgba(32, 152, 206, .1) 7px );
      font-size: 15px;
    }
  }
}
[data-bgb=jyuken-title3].jyuken-red {
  h3 {
    background-image: repeating-linear-gradient(135deg, transparent, transparent 4px, rgba(230, 86, 103, .1) 4px, rgba(230, 86, 103, .1) 10px );
    color: $j-red;
    @include mq(sp) {
      background-image: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(230, 86, 103, .1) 3px, rgba(230, 86, 103, .1) 7px );
    }
  }
}
[data-bgb=jyuken-title3].jyuken-yellow {
  h3 {
    background-image: repeating-linear-gradient(135deg, transparent, transparent 4px, rgba(255, 195, 71, .1) 4px, rgba(255, 195, 71, .1) 10px );
    color: $j-yellow;
    @include mq(sp) {
      background-image: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255, 195, 71, .1) 3px, rgba(255, 195, 71, .1) 7px );
    }
  }
}

[data-bgb=jyuken-title4] {
  h4 {
    color: $j-blue;
    &::before, &::after {
      background-color: $j-blue;
    }
  }
}
[data-bgb=jyuken-title4].jyuken-red {
  h4 {
    color: $j-red;
    &::before, &::after {
      background-color: $j-red;
    }
  }
}
[data-bgb=jyuken-title4].jyuken-yellow {
  h4 {
    color: $j-yellow;
    &::before, &::after {
      background-color: $j-yellow;
    }
  }
}
[data-bgb=jyuken-button] {
  a.bgt-btn {
    border-width: 3px;
    border-color: $j-blue;
    background: $j-blue;
    color: #fff;
    font-family: $font-notosans;
    font-weight: 700;
    &::after {
      border-color: transparent transparent transparent #fff;
    }
    &:hover {
      @include mq(pc) {
        border-color: $j-blue;
        background: $j-blue2;
        color: $j-blue;
      }
      &::after {
        @include mq(pc) {
          border-color: transparent transparent transparent $j-blue;
        }
      }
    }
  }
}
[data-bgb=jyuken-button].jyuken-red {
  a.bgt-btn {
    border-width: 3px;
    border-color: $j-red;
    background: $j-red;
    color: #fff;
    font-family: $font-notosans;
    font-weight: 700;
    &::after {
      border-color: transparent transparent transparent #fff;
    }
    &:hover {
      @include mq(pc) {
        background: $j-red2;
        color: $j-red;
      }
      &::after {
        @include mq(pc) {
          border-color: transparent transparent transparent $j-red;
        }
      }
    }
  }
}
[data-bgb=jyuken-button].jyuken-yellow {
  a.bgt-btn {
    border-width: 3px;
    border-color: $j-yellow;
    background: $j-yellow;
    color: #fff;
    font-family: $font-notosans;
    font-weight: 700;
    &::after {
      border-color: transparent transparent transparent #fff;
    }
    &:hover {
      @include mq(pc) {
        background: $j-yellow2;
        color: $j-yellow;
      }
      &::after {
        @include mq(pc) {
          border-color: transparent transparent transparent $j-yellow;
        }
      }
    }
  }
}