$largeRange: 1700px;
$mediumRange: 970px;
$smallRange: 760px;
$tinyRange: 321px;
$navWidth: 1090px;
@mixin mq($media) {
	@if $media == pc {
		@media screen and (min-width: #{$smallRange + 1}) {
			@content;
		}
	}
	@if $media == wide {
		@media screen and (min-width: #{$navWidth + 1}) {
			@content;
		}
	}
	@if $media == nav {
		@media screen and (max-width: #{$mediumRange}) {
			@content;
		}
	}
	@if $media == sp {
		@media screen and (max-width: #{$smallRange}) {
			@content;
		}
	}
	@if $media == tiny {
		@media screen and (max-width: #{$tinyRange}) {
			@content;
		}
	}
	@if $media == mov {
		@media screen and (max-width: #{$largeRange}) {
			@content;
		}
	}
}
