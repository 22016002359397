$black: #1A1311;
$main: #174F9E;
$sub: #6B8DC4;
$light-main: #B0C2E0;
$accent: #F07700;
$yellow: #FFEC00;
$sky: #EEF6FE;
$futu: #93d7d9;
$kogyo: #ffd080;

$j-blue: #2098CE;
$j-blue2: rgba(32,152,206,.1);
$j-red: #E65667;
$j-red2: rgba(230,86,103,.1);
$j-yellow: #FFC347;
$j-yellow2: rgba(255,195,71,.1);

$base-lh: 2;
$base-fs: 16px;
$sp-fs: 13px;

$mincho: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "ＭＳ 明朝", serif;
$gothic: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Slabo+13px&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Slabo+27px&display=swap');

$font-notosans: 'Noto Sans JP', sans-serif; /* Regular 400, Bold 700 */
$font-josefin: 'Josefin Sans', sans-serif; /* Regular 400, Bold 700 */
$font-notoserif: 'Noto Serif JP', serif; /* Medium 500 */
$font-katura: kinuta-maruminkatura-stdn, serif; /* Regular 400 */
$font-slabo: 'Slabo 13px', serif;
$font-slabo27: 'Slabo 27px', serif;
