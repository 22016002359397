.bge-contents {
  padding-bottom: 60px;
  @include mq(sp) {
    padding-bottom: 30px;
    overflow: hidden;
  }
// 上下マージン
  [data-bgb] {
    margin: 0 0 3em;
  }
  [data-bgb=title],[data-bgb=title2] {
    @include mq(sp) {
      margin: 0 0 1.5em;
    }
  }

// 最大幅指定
  [data-bgb]:not(.bge-wide) {
    @include base(1170,20,5vw);
  }

// .bge-wide 幅いっぱい背景色
  [data-bgb].bge-wide {
    padding: 50px 0;
    background: $sky;
    @include mq(sp) {
      padding: 25px 0;
    }
// .bge-wide.cream 幅いっぱい背景色クリーム色
    &.cream {
      background: #FFFDEA;
    }
// .bge-wide.gray 幅いっぱい背景色灰色
    &.gray {
      background: #F4F5F2;
    }
    [data-bgt] {
      @include base(1170,20,5vw);
      h2 {
        margin: 0 0 60px;
        @include mq(sp) {
          margin: 0 0 30px;
        }
      }
    }
  }
// 背景色水色
  [data-bgb].bge-blue {
    max-width: 1130px;
    padding: 2em 3em;
    background: $sky;
  }
// 背景色受験青
  [data-bgb].bg-jyuken-blue {
    max-width: 1130px;
    padding: 2em 3em;
    background: $j-blue2;
  }
// 背景色受験赤
  [data-bgb].bg-jyuken-red {
    max-width: 1130px;
    padding: 2em 3em;
    background: $j-red2;
  }
// 背景色受験黄
  [data-bgb].bg-jyuken-yellow {
    max-width: 1130px;
    padding: 2em 3em;
    background: $j-yellow2;
  }
// 注意喚起背景色赤
  [data-bgb].bg-notice {
    max-width: 1130px;
    padding: 2em 3em;
    background: $j-red;
    color: #fff;
    -webkit-font-smoothing: subpixel-antialiased;
  }

// リストレイアウト（上下ボーダー）
  [data-bgb].bge-list {
    border-top: 1px solid #ccc;
    padding-top: 16px;
    padding-bottom: 16px;
    &:last-child, &.last {
      border-bottom: 1px solid #ccc;
    }
    p {
      margin: 0;
      line-height: 1.5;
    }
  }

// 画像スマホのみ幅いっぱい
  [data-bgb].bge-spwide {
    @include base(1170,20,0);
  }

// 画像幅いっぱい
  [data-bgb].bge-imgwide {
    img {
      width: 100%;
    }
  }

// wysiwig内タイトル下マージン
  [data-bgb=wysiwyg2] h2, [data-bgb=wysiwyg] h2,
  [data-bgb=wysiwyg2] h3, [data-bgb=wysiwyg] h3 {
    margin-bottom: 1em;
  }

// アンカーリンクhover
  [data-bgb] a[href] img {
    transition: .5s;
  }
  [data-bgb] a[href]:hover img {
    opacity: .7;
  }

// 画像下マージン
  [data-bgb=image-text2] [data-bgt=image],
  [data-bgb=image-text3] [data-bgt=image],
  [data-bgb=image-text4] [data-bgt=image],
  [data-bgb=image-text5] [data-bgt=image],
  [data-bgb=image-link-text2] [data-bgt=image-link],
  [data-bgb=image-link-text3] [data-bgt=image-link],
  [data-bgb=image-link-text4] [data-bgt=image-link],
  [data-bgb=image-link-text5] [data-bgt=image-link] {
    margin-bottom: 20px;
  }

  [data-bgb=image-text2] .bgt-grid,
  [data-bgb=image-text3] .bgt-grid,
  [data-bgb=image-text4] .bgt-grid,
  [data-bgb=image-text5] .bgt-grid {
    @include mq(sp) {
      margin-bottom: 20px;
    }
  }
  [data-bgb=image-text2] .bgt-grid--last,
  [data-bgb=image-text3] .bgt-grid--last,
  [data-bgb=image-text4] .bgt-grid--last,
  [data-bgb=image-text5] .bgt-grid--last {
    @include mq(sp) {
      margin-bottom: 0;
    }
  }

  [data-bgb=text-image1] .bgt-grid--first,
  [data-bgb=text-image2] .bgt-grid--last {
    @include mq(sp) {
      margin-bottom: 20px;
    }
  }
  [data-bgb=text-image1].img-reverse .bgt-grid--first,
  [data-bgb=text-image2].img-reverse .bgt-grid--last {
    @include mq(sp) {
      margin-bottom: 0;
    }
  }
  [data-bgb=text-image1].img-reverse .bgt-grid--last,
  [data-bgb=text-image2].img-reverse .bgt-grid--first {
    @include mq(sp) {
      margin-bottom: 20px;
    }
  }


//2カラム画像スマホ位置調整
[data-bgb=text-image1] .bgt-grid--first {
  padding-right: 20px;
  @include mq(sp) {
    padding-right: 0;
  }
}
[data-bgb=text-image1] .bgt-grid--last {
  padding-left: 20px;
  @include mq(sp) {
    padding-left: 0;
  }
}
[data-bgb=text-image2] .bgt-grid--first {
  padding-right: 20px;
  @include mq(sp) {
    padding-right: 0;
  }
}
[data-bgb=text-image2] .bgt-grid--last {
  padding-left: 20px;
  @include mq(sp) {
    padding-left: 0;
  }
}
[data-bgb=text-image2] {
  @include mq(sp) {
    display: flex;
    flex-direction: column-reverse;
  }
}
[data-bgb=text-image1].img-reverse {
  @include mq(sp) {
    display: flex;
    flex-direction: column-reverse;
  }
}
[data-bgb=text-image2].img-reverse {
  @include mq(sp) {
    display: flex;
    flex-direction: column;
  }
}

//ダウンロードファイルアイコン
  .bgt-download-file__link[href] .bgt-link__icon {
    margin-right: 10px;
  }
  .bgt-download-file__link[href] .bgt-link__icon:after {
    display: inline-block;
    content: "\f0f6";
    transform: translateY(-3px);
  }

  a.dl {
    display: inline-block;
    color: $main;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    padding: .7em 1em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    text-decoration: underline;
    @include mq(sp) {
      font-size: 14px;
    }
    i {
      margin-right: 10px;
      &::after {
        display: inline-block;
        content: "\f0f6";
        font-size: 1.3em;
        transform: translateY(-4px);
        font-family: FontAwesome,serif;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        text-rendering: auto;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        vertical-align: bottom;
      }
    }
    &.box {
      margin: 16px 0 8px;
      border: 1px solid #ccc;
      text-decoration: none !important;
      &:hover {
        background: $sky;
      }
    }
  }

// 2カラムWYSIWYG調整
  [data-bgb=wysiwyg2] {
    display: flex;
    float: none;
    @include mq(sp) {
      display: block;
    }
    .bgt-grid {
      width: 49%;
      margin-right: 2%;
      @include mq(sp) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }
      &--last {
        margin-right: 0;
      }
    }
  }
// 3カラムWYSIWYG
  [data-bgb=wysiwyg3] {
    display: flex;
    @include mq(sp) {
      display: block;
    }
    .bgt-grid {
      width: 32%;
      margin-right: 2%;
      @include mq(sp) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      &--last {
        margin-right: 0;
      }
    }
  }

// border付きWYSIWYG
  [data-bgb=wysiwyg].bge-border .bgt-container,
  [data-bgb].bge-border .bgt-grid {
    padding: 20px;
    border: 1px solid #ccc;
  }
  [data-bgb].bge-border-02 {
    .bgt-grid--last {
      border: 0;
      @include mq(sp) {
        display: none;
      }
    }
  }
  [data-bgb].bge-border-01 {
    .bgt-grid:not(:first-child) {
      border: 0;
      @include mq(sp) {
        display: none;
      }
    }
  }
  [data-bgb=wysiwyg].bge-border.clr-bl .bgt-container,
  [data-bgb].bge-border.clr-bl .bgt-grid {
    border-color: $main;
  }

// 受験生情報サイトborder付きWYSIWYG
// 青
  [data-bgb=wysiwyg].bd-jyuken-blue .bgt-container,
  [data-bgb].bd-jyuken-blue .bgt-grid {
    padding: 20px;
    border: 3px solid $j-blue;
  }
// 赤
  [data-bgb=wysiwyg].bd-jyuken-red .bgt-container,
  [data-bgb].bd-jyuken-red .bgt-grid {
    padding: 20px;
    border: 3px solid $j-red;
  }
// 黄
  [data-bgb=wysiwyg].bd-jyuken-yellow .bgt-container,
  [data-bgb].bd-jyuken-yellow .bgt-grid {
    padding: 20px;
    border: 3px solid $j-yellow;
  }

// アンカータグ
  [data-bgb] a[href]:not(.bgt-btn) {
    color: $main;
    //text-decoration: underline;
  }

// ページ内リンク用ボタン
  .page-link .bgt-btn::after {
      border-width: 10px 6px 0 6px;
      border-color: $main transparent transparent transparent;
    @include mq(sp) {
      border-width: 8px 5px 0 5px;
    }
  }

// WYSIWYG内記述用ボタン
  .btn-box {
    a {
      position: relative;
      display: inline-block;
      min-width: 280px;
      padding: 16px 45px 16px 20px;
      background: $sky;
      border: 1px solid $sky;
      border-radius: 50px;
      color: $main;
      font-family: $font-katura;
      font-size: 20px;
      line-height: 1.2;
      text-decoration: none;
      vertical-align: middle;
      text-decoration: none !important;
      transition: .5s;
      @include mq(sp) {
        width: 100%;
        padding: 12px 40px 12px 20px;
        font-size: 15px;
      }
      &::after {
        position: absolute;
        right: 25px;
        top: 50%;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 0 6px 10px;
        border-color: transparent transparent transparent $main;
        transform: translateY(-50%);
        transition: .5s;
        @include mq(sp) {
          right: 20px;
          border-width: 5px 0 5px 8px;
        }
      }
    }
  }

// キャプション風タイトル
  .ttl-caption {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    &::before {
      content: "■ ";
      color: $main;
    }
    @include mq(sp) {
      font-size: 16px;
    }
  }

// FAQ
  [data-bgb=faq] .faq_list_q {
    position: relative;
    margin: 0;
    padding: 24px 30px 24px 90px;
    background: $sky;
    color: $main;
    font-size: 22px;
    line-height: 1.3636;
    @include mq(sp) {
      padding: 18px 20px 18px 56px;
      font-size: 15px;
    }
    &::before {
      position: absolute;
      left: 30px;
      top: 25px;
      content: "";
      width: 32px;
      height: 32px;
      background: $main;
      transform: rotate(45deg);
      @include mq(sp) {
        left: 14px;
        top: 19px;
        width: 26px;
        height: 26px;
      }
    }
    &::after {
      position: absolute;
      left: 38px;
      top: 24px;
      content: "Q";
      display: block;
      color: #fff;
      @include mq(sp) {
        left: 21px;
        top: 19px;
      }
    }
  }
  [data-bgb=faq] .faq_list_a {
    position: relative;
    margin: 0;
    padding: 30px 30px 0 90px;
    line-height: 1.875;
    @include mq(sp) {
      padding: 15px 0 0;
      font-size: 13px;
    }
  }

// 1カラムダウンロード時は左寄せ
  [data-bgb=download-file] {
    text-align: left;
  }
  [data-bgb=download-file2],
  [data-bgb=download-file3] {
    @include mq(sp) {
      text-align: left;
    }
  }

// 2カラムYoutube
  [data-bgb=youtube2] {
    @include flex(nowrap,space-between);
    .bgt-grid--first {
      @include mq(pc) {
        padding-right: 20px;
      }
      @include mq(sp) {
        margin-bottom: 3em;
      }
    }
    .bgt-grid--last {
      @include mq(pc) {
        padding-left: 20px;
      }
    }
  }

  [data-bgb=youtube2-wysiwyg] {
    @include flex(nowrap,space-between);
    .bgt-grid--first {
      @include mq(pc) {
        padding-right: 20px;
      }
      @include mq(sp) {
        margin-bottom: 3em;
      }
    }
    .bgt-grid--last {
      @include mq(pc) {
        padding-left: 20px;
      }
    }
    [data-bgt=youtube] {
      margin-bottom: 10px;
    }
  }

// 2カラム画像上下テキスト
  [data-bgb=text-image-text2] {
    @include flex(nowrap,space-between);
    .bgt-grid--first {
      @include mq(pc) {
        padding-right: 20px;
      }
      @include mq(sp) {
        margin-bottom: 3em;
      }
    }
    .bgt-grid--last {
      @include mq(pc) {
        padding-left: 20px;
      }
    }
    [data-bgt=image] {
      margin-bottom: 20px;
      @include mq(sp) {
        margin-bottom: 10px
      }
      img {
        width: 100%;
      }
    }
  }

// 在校生メッセージ
  [data-bgb=course-student],
  [data-bgb=course-student].bge-normal1 {
    @include flex();
    position: relative;
    max-width: 1130px !important;
    padding-top: 30px;
    background: #E5F2F9;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      width: 100%;
      height: 40px;
      background: #fff url(/img/course/bg_message_01.png) top left no-repeat;
      @include mq(sp) {
        background: #fff url(/img/course/sp/bg_message_01.png) top left no-repeat;
        background-size: 100% auto;
      }
    }
    .bgt-grid--first {
      padding-right: 20px;
      @include mq(sp) {
        padding-right: 0;
      }
    }
    .bgt-grid--last {
      padding-top: 30px;
      padding-bottom: 30px;
      padding-right: 40px;
      padding-left: 20px;
      @include mq(sp) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .ttl-c-message {
      color: #007FC7;
      font-size: 26px;
      font-weight: 700;
      @include mq(sp) {
        font-size: 18px;
      }
    }
    .text-c-message {
      margin-bottom: 40px;
    }
    .box-c-message {
      position: relative;
      padding: 50px 36px 24px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      background: #fff;
      @include mq(sp) {
        padding: 40px 24px 20px;
      }
      &::before {
        position: absolute;
        top: -18px;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        display: block;
        width: 195px;
        height: 53px;
        background: url(/img/course/ttl_message_s.png) 0 0 no-repeat;
        @include mq(sp) {
          width: 170px;
          height: 46px;
          background-size: 170px auto;
        }
      }
    }
  }
  [data-bgb=course-student].bge-normal2 {
    background: #E5F5F7;
    &::before {
      background: #fff url(/img/course/bg_message_02.png) top left no-repeat;
      @include mq(sp) {
        background: #fff url(/img/course/sp/bg_message_02.png) top left no-repeat;
        background-size: 100% auto;
      }
    }
    .ttl-c-message {
      color: #00A0AE;
    }
  }
  [data-bgb=course-student].bge-industry {
    background: #FEF5E5;
    &::before {
      background: #fff url(/img/course/bg_message_03.png) top left no-repeat;
      @include mq(sp) {
        background: #fff url(/img/course/sp/bg_message_03.png) top left no-repeat;
        background-size: 100% auto;
      }
    }
    .ttl-c-message {
      color: #F07700;
    }
  }

// 教師メッセージ
  [data-bgb=course-teacher] {
    @include flex();
    max-width: 1130px !important;
    background: #FFFDEA;
    .bgt-grid--first {
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 40px;
      padding-right: 20px;
      @include mq(sp) {
        padding-right: 0;
        padding-left: 0;
      }
    }
    .bgt-grid--last {
      padding-left: 20px;
      @include mq(sp) {
        padding-left: 0;
      }
    }
    .ttl-c-message {
      position: relative;
      margin-bottom: 20px;
      padding-top: 100px;
      color: $main;
      font-size: 26px;
      font-weight: 700;
      line-height: 1.46;
      @include mq(sp) {
        margin-bottom: 10px;
        padding-top: 70px;
        font-size: 18px;
      }
      &::before {
        position: absolute;
        top: 10px;
        left: 0;
        content: "";
        display: block;
        width: 242px;
        height: 80px;
        background: url(/img/course/ttl_message_t.png) 0 0 no-repeat;
        @include mq(sp) {
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 170px;
          height: 56px;
          background-size: 170px auto;
        }
      }
    }
    .text-c-message {
    }

    &.bge-teacher-blue {
      flex-direction: row-reverse;
      background: #eef6fe;
      .ttl-c-message {
        &::before {
          background-image: url(/img/course/ttl_message_t_bl.png);
        }
      }
    }
  }

// ページリンク（1〜4コ）
  [data-bgt=page-link] .bgt-pagelink {
    position: relative;
    display: flex;
    align-items: center;
    width: 22.65%;
    height: 70px;
    padding: 0 46px 0 22px;
    border: 1px solid $main;
    color: $main;
    font-family: $font-katura;
    font-size: 19px;
    letter-spacing: 0;
    line-height: 1.2;
    text-decoration: none !important;
    @include mq(sp) {
      width: 49%;
      height: 50px;
      padding: 0 24px 0 10px;
      font-size: 14px;
    }
    &::before {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $main;
      @include mq(sp) {
        right: 12px;
        width: 12px;
        height: 12px;
      }
    }
    &::after {
      position: absolute;
      top: 50%;
      right: 26px;
      transform: translateY(-50%);
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 4px 0;
      border-color: #fff transparent transparent;
      @include mq(sp) {
        right: 15px;
        border-width: 5px 3px 0;
      }
    }
    &:hover {
      @include mq(pc) {
        border-color: #fff;
        background: linear-gradient(-90deg,#cde2f7 0,#e4f9fa 100%);
      }
    }
    .pgt-pagelink_text {
    }
  }

  [data-bgb=page-link2],[data-bgb=page-link3],[data-bgb=page-link4] {
    @include flex-sp(wrap);
    .bgt-grid {
      width: 22.65%;
      margin-right: 3.1%;
      margin-bottom: 20px;
      @include mq(sp) {
        width: 49%;
        margin-right: 2%;
        margin-bottom: 10px;
      }
      &:nth-child(2n) {
        @include mq(sp) {
          margin-right: 0;
        }
      }
    }
    .bgt-grid--last {
      margin-right: 0;
    }
    .bgt-pagelink {
      width: 100% !important;
    }
  }

// 保護者宛文書PDF
  [data-bgb=document] {
    .doc-pdf {
      padding: 10px 15px 20px;
      border-top: 1px solid $main;
    }
    &:nth-child(odd) {
      .doc-pdf {
        background: $sky;
      }
    }
    .doc-pdf-info {
      margin-bottom: 10px;
      & * {
        display: inline-block;
      }
      dt {
        color: #444;
      }
      dd {
        margin-right: 30px;
        font-weight: 700;
        font-size: 17px;
        @include mq(sp) {
          font-size: 15px;
        }
      }
    }
    .doc-pdf-dl {
      a {
        padding: .3em 1em .5em;
        border: 1px solid $main;
        background-color: #fff;
        text-decoration: none;
      }
    }
  }

// 部活動画像
  .club-slide {
    margin-top: 3em;
  }

// スマホ時横スクロール画像3列
  [data-bgb=image3-scroll] {
    margin-top: 3em;
    @include mq(sp) {
      padding-bottom: 10px;
      overflow-x: scroll;
    }
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: $sky;
    }
    &::-webkit-scrollbar-thumb {
      background: $main;
    }

    .image3-scroll-inner {
      @include mq(sp) {
        width: 800px;
        padding-right: 5vw;
      }
    }

    .bgt-grid {
      width: 33.3333%;
      float: left;
      padding-left: 6.66667px;
      padding-right: 6.66667px;
      &:first-child {
        padding-left: 0;
        padding-right: 13.33333px;
      }
      &:nth-child(3) {
        padding-left: 13.33333px;
        padding-right: 0;
      }
    }
  }



} //.bge-contents








// 管理画面表示
.bge_content {
  [data-bgb].pc [data-bgt]::before {
    content: "　　パソコン版　　";
    background: yellow;
  }
  [data-bgb].sp [data-bgt]::before {
    content: "　　スマホ版　　";
    background: yellow;
  }
}

// カリキュラム
[data-bgb].curriculum {
  table:not(.table-scroll) {
    @include mq(sp) {
      display: table;
    }
    tr {
      @include mq(sp) {
        display: table-row;
      }
      &:first-child {
        th,td {
          background: $sky;
        }
      }
      th {
        padding: 5px 10px;
        font-size: 14px;
        line-height: 1.2;
        text-align: center;
        @include mq(sp) {
          display: table-cell;
          width: auto;
        }
        &:first-child {
          background: $sky;
        }
      }
      td {
        padding: 5px 10px;
        font-size: 14px;
        line-height: 1.2;
        text-align: center;
        @include mq(sp) {
          display: table-cell;
        }
        &:not(:first-child) {
          @include mq(sp) {
            width: 9999px;
          }
        }
        &:first-child {
          width: 60px;
          background: $sky;
          @include mq(sp) {
          }
        }
      }
    }
  }
}


.ob-box {
  .ob-info {
    margin-bottom: 10px;
  }
  .ob-info {
    .ob-photo {
      float: left;
      width: 90px;
      margin-right: 15px;
      margin-bottom: 10px;
    }
    .ob-text {
      margin-bottom: 10px;
      dt {
        margin-bottom: 10px;
        font-size: 15px;
        color: $main;
        line-height: 1.4;
        span {
          font-size: 18px;
          font-weight: 700;
        }
      }
      dd {
        font-size: 13px;
        color: #999;
        line-height: 1.4;
      }
      dd span {
        margin-right: 4px;
        font-size: 17px;
        font-weight: 700;
      }
    }
  }
  .ob-copy {
    margin-top: 20px;
    color: $accent;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4;
    @include mq(sp) {
      clear: both;
      margin-top: 0;
    }
  }
  .ob-message {
    clear: both;
    line-height: 1.6;
    font-size: 15px;
    @include mq(sp) {
      padding-top: 0;
    }
    p {
      margin-bottom: 0;
    }
    strong {
      color: $main;
    }
  }
}
[data-bgb=wysiwyg2].ob-box {
  .bgt-grid [data-bgt] > :last-child {
    margin-bottom: 0 !important;
  }
  .ob-info {
    .ob-photo {
      width: 150px;
      @include mq(sp) {
        width: 90px;
      }
      img {
        width: 100%;
      }
    }
    .ob-text {
    }
  }
}