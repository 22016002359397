@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Slabo+13px&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Slabo+27px&display=swap");
/* Regular 400, Bold 700 */
/* Regular 400, Bold 700 */
/* Medium 500 */
/* Regular 400 */
@font-face {
  font-family: FontAwesome;
  src: url(../fonts/fontawesome-webfont.eot?v=4.6.3);
  src: url(../fonts/fontawesome-webfont.eot?#iefix&v=4.6.3) format("embedded-opentype"), url(../fonts/fontawesome-webfont.woff2?v=4.6.3) format("woff2"), url(../fonts/fontawesome-webfont.woff?v=4.6.3) format("woff"), url(../fonts/fontawesome-webfont.ttf?v=4.6.3) format("truetype"), url(../fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular) format("svg");
  font-weight: 400;
  font-style: normal;
}

[data-bgb=title] h2 {
  position: relative;
  margin: 2em 0;
  padding: .3em .5em;
  background: none;
  color: #174F9E;
  font-family: kinuta-maruminkatura-stdn, serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;
  overflow: hidden;
}

@media screen and (max-width: 760px) {
  [data-bgb=title] h2 {
    font-size: 20px;
  }
}

[data-bgb=title] h2:before {
  position: absolute;
  bottom: -.2em;
  left: 50%;
  content: "";
  display: block;
  width: 0;
  height: 0;
  margin: .6em auto;
  border-style: solid;
  border-width: 8px 17.5px 0 17.5px;
  border-color: #174F9E transparent transparent transparent;
  transform: translateX(-50%);
}

@media screen and (max-width: 760px) {
  [data-bgb=title] h2:before {
    border-width: 5px 10px 0 10px;
  }
}

[data-bgb=title] h2:after {
  content: "";
  display: block;
  width: 4em;
  height: 2px;
  margin: .6em auto;
  background: #174F9E;
  margin: .6em auto;
}

[data-bgb=title1] h2, [data-bgb=wysiwyg] h2, [data-bgb=wysiwyg2] h2,
[data-bgb=youtube2-wysiwyg] h2 {
  margin: 2em 0;
  padding: .5em 0;
  border-top: 1px solid #174F9E;
  border-bottom: 1px solid #174F9E;
  color: #174F9E;
  font-family: kinuta-maruminkatura-stdn, serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 1.2;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb=title1] h2, [data-bgb=wysiwyg] h2, [data-bgb=wysiwyg2] h2,
  [data-bgb=youtube2-wysiwyg] h2 {
    margin: 1.6em 0;
    padding: 1em 0;
    font-size: 16px;
  }
}

[data-bgb=title1] h2 a, [data-bgb=wysiwyg] h2 a, [data-bgb=wysiwyg2] h2 a,
[data-bgb=youtube2-wysiwyg] h2 a {
  display: block;
  text-decoration: none;
}

[data-bgb=title1] h2 a:hover, [data-bgb=wysiwyg] h2 a:hover, [data-bgb=wysiwyg2] h2 a:hover,
[data-bgb=youtube2-wysiwyg] h2 a:hover {
  background: #EEF6FE;
}

[data-bgb=title1] h2:first-child, [data-bgb=wysiwyg] h2:first-child, [data-bgb=wysiwyg2] h2:first-child,
[data-bgb=youtube2-wysiwyg] h2:first-child {
  margin-top: 0;
}

[data-bgb=title1a] h2 {
  margin: 2em 0;
  padding: .5em 1.4em;
  background: #174F9E url(/img/burger/bg_ttl_h3.png) right top no-repeat;
  color: #fff;
  font-family: kinuta-maruminkatura-stdn, serif;
  font-size: 23px;
  font-weight: 400;
  line-height: 1.2;
  overflow: hidden;
}

@media screen and (max-width: 760px) {
  [data-bgb=title1a] h2 {
    margin: 1.6em 0;
    background-size: 665px 77px;
    font-size: 16px;
  }
}

[data-bgb=title1a] h2 a {
  display: block;
  color: #fff;
  text-decoration: none;
}

[data-bgb=title1a] h2:first-child {
  margin-top: 0;
}

[data-bgb=title2] h3, [data-bgb=wysiwyg] h3, [data-bgb=youtube2-wysiwyg] h3 {
  margin: 2em 0;
  padding: .5em 1.4em;
  background: #174F9E url(/img/burger/bg_ttl_h3.png) right top no-repeat;
  color: #fff;
  font-family: kinuta-maruminkatura-stdn, serif;
  font-size: 23px;
  font-weight: 400;
  line-height: 1.2;
  overflow: hidden;
}

@media screen and (max-width: 760px) {
  [data-bgb=title2] h3, [data-bgb=wysiwyg] h3, [data-bgb=youtube2-wysiwyg] h3 {
    margin: 1.6em 0;
    background-size: 665px 77px;
    font-size: 16px;
  }
}

[data-bgb=title2] h3 a, [data-bgb=wysiwyg] h3 a, [data-bgb=youtube2-wysiwyg] h3 a {
  display: block;
  color: #fff;
  text-decoration: none;
}

[data-bgb=title2a] h3 {
  margin-bottom: 20px;
  color: #174F9E;
  font-family: kinuta-maruminkatura-stdn, serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb=title2a] h3 {
    margin-bottom: 10px;
    font-size: 19px;
  }
}

[data-bgb] h3:first-child {
  margin-top: 0;
}

[data-bgb] h4 {
  position: relative;
  margin: 1em 0;
  padding: .3em .2em .5em 1.4em;
  border-bottom: 2px solid #CFCFCF;
  color: #174F9E;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
}

@media screen and (max-width: 760px) {
  [data-bgb] h4 {
    font-size: 15px;
  }
}

[data-bgb] h4:first-child {
  margin-top: 0;
}

[data-bgb] h4:before {
  position: absolute;
  top: 13px;
  left: 0;
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  background: #174F9E;
  transform: rotate(45deg);
  transform-origin: 50% 50% 0;
}

@media screen and (max-width: 760px) {
  [data-bgb] h4:before {
    top: 9px;
  }
}

[data-bgb] h4:after {
  position: absolute;
  bottom: -2px;
  left: 0;
  content: "";
  width: 20%;
  height: 2px;
  background-color: #174F9E;
}

[data-bgb] h5 {
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1.2;
  margin: 1em 0;
  padding: .3em 0;
  border-bottom: 1px dashed #999;
  overflow: hidden;
}

[data-bgb] h5:first-child {
  margin-top: 0;
}

[data-bgb] h6 {
  margin: 1em 0 .8em;
  color: #174F9E;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
}

@media screen and (max-width: 760px) {
  [data-bgb] h6 {
    margin: 0.6em 0;
    font-size: 16px;
  }
}

[data-bgb] h6:first-child {
  margin-top: 0;
}

[data-bgb] p {
  margin: 1em 0;
}

[data-bgb] p.title-01 {
  margin: 2em 0;
  padding: .5em 0;
  border-top: 1px solid #174F9E;
  border-bottom: 1px solid #174F9E;
  color: #174F9E;
  font-family: kinuta-maruminkatura-stdn, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb] p.title-01 {
    margin: 0.6em 0 1.6em;
    padding: .75em 0;
    font-size: 16px;
  }
}

[data-bgb].title-01 p {
  margin: 2em 0;
  padding: .5em 0;
  border-top: 1px solid #174F9E;
  border-bottom: 1px solid #174F9E;
  color: #174F9E;
  font-family: kinuta-maruminkatura-stdn, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb].title-01 p {
    margin: 0.6em 0 1.6em;
    padding: .75em 0;
    font-size: 16px;
  }
}

[data-bgb] p.title-02 {
  margin: 2em 0 .8em;
  color: #174F9E;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
}

@media screen and (max-width: 760px) {
  [data-bgb] p.title-02 {
    margin: 0.6em 0;
    font-size: 16px;
  }
}

[data-bgb].title-02 p {
  margin: 2em 0 .8em;
  color: #174F9E;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
}

@media screen and (max-width: 760px) {
  [data-bgb].title-02 p {
    margin: 0.6em 0;
    font-size: 16px;
  }
}

[data-bgb] p:first-child {
  margin-top: 0;
}

[data-bgb] p:last-child {
  margin-bottom: 0;
}

[data-bgb] li {
  list-style: inherit;
}

[data-bgb] li:first-child {
  margin-top: 0;
}

[data-bgb] ul {
  margin: 1em 0;
}

[data-bgb] ul:first-child {
  margin-top: 0;
}

[data-bgb] ul ul {
  margin: 0 0 0 1.8em;
}

[data-bgb] ul ul:first-child {
  margin-top: 0;
}

[data-bgb] ul li {
  list-style-position: inside;
  list-style-type: disc;
  margin: 0 0 .2em 2.5em;
  text-indent: -1.5em;
}

[data-bgb] ol {
  margin: 1em 0 1em 1.3em;
}

[data-bgb] ol:first-child {
  margin-top: 0;
}

[data-bgb] ol ol {
  margin: 0 0 0 2em;
}

[data-bgb] ol ol:first-child {
  margin-top: 0;
}

[data-bgb] ol li {
  margin: 0 0 .2em -1.3em;
  text-indent: -1.3em;
  list-style-position: inside;
  list-style-type: decimal;
}

[data-bgb] blockquote {
  position: relative;
  margin: 1em 0;
  padding: 1px 2.5em;
  border-radius: 5px;
  background: none;
  border: 2px solid #ccc;
  overflow: hidden;
  color: #888;
  font-size: .9em;
}

[data-bgb] blockquote:first-child {
  margin-top: 0;
}

[data-bgb] blockquote:before {
  content: "“";
  top: .3em;
  left: .25em;
}

[data-bgb] blockquote:after, [data-bgb] blockquote:before {
  display: block;
  font-family: serif;
  position: absolute;
  z-index: 0;
  color: #ccc;
  font-size: 3em;
}

[data-bgb] blockquote:after {
  content: "”";
  bottom: -.3em;
  right: .25em;
}

[data-bgb] table {
  min-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
  margin: 1em 0;
}

@media screen and (max-width: 768px) {
  [data-bgb] table:not(.table-scroll) {
    width: 100%;
    display: block;
  }
}

[data-bgb] table:first-child {
  margin-top: 0;
}

[data-bgb] table caption {
  /* margin-bottom: 5px;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  &::before {
    content: "■ ";
    color: $main;
  }
  @include mq(sp) {
    font-size: 16px;
  } */
}

@media screen and (max-width: 768px) {
  [data-bgb] table:not(.table-scroll) caption, [data-bgb] table:not(.table-scroll) tbody, [data-bgb] table:not(.table-scroll) tfoot {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  [data-bgb] table:not(.table-scroll) tr {
    display: block;
    margin-top: -1px;
  }
}

[data-bgb] table tr td {
  border-top: 1px solid #174F9E;
  border-bottom: 1px solid #174F9E;
  background: #fff;
}

[data-bgb] table tr td, [data-bgb] table tr th {
  text-align: left;
  padding: 1em 2em;
  border-top: 1px solid #174F9E;
  border-bottom: 1px solid #174F9E;
}

@media screen and (max-width: 760px) {
  [data-bgb] table tr td, [data-bgb] table tr th {
    padding: .8em 1em;
    line-height: 1.5;
  }
}

@media screen and (max-width: 768px) {
  [data-bgb] table:not(.table-scroll) tr td, [data-bgb] table:not(.table-scroll) tr th {
    display: block;
    width: auto;
  }
}

[data-bgb] table tr th {
  font-weight: 500;
  background: #EEF6FE;
  color: #174F9E;
}

@media screen and (max-width: 768px) {
  [data-bgb] table:not(.table-scroll) tr th {
    border-bottom: none;
  }
}

[data-bgb] table tr td {
  font-weight: 400;
}

[data-bgb] table tr th,
[data-bgb] table tr td {
  border-right: 1px solid #f2f2f2;
  border-left: 1px solid #f2f2f2;
}

[data-bgb] strong {
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
}

[data-bgb] u {
  text-decoration: underline;
}

[data-bgb] s, [data-bgb] u {
  font-weight: 400;
  font-style: normal;
}

[data-bgb] s {
  text-decoration: line-through;
}

[data-bgb] sub {
  vertical-align: sub;
}

[data-bgb] sub, [data-bgb] sup {
  font-size: .8em;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
}

[data-bgb] sup {
  vertical-align: super;
}

[data-bgb] hr {
  width: 100%;
  height: 1px;
  color: #174F9E;
  background: none;
  border-top: 1px dashed #174F9E;
  margin: 50px auto;
}

[data-bgb] hr:first-child {
  margin-top: 0;
}

.bgt-box__caption {
  margin-bottom: -20px;
  padding: 15px 0;
  background: none;
  color: #1A1311;
  font-family: kinuta-maruminkatura-stdn, serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .bgt-box__caption {
    font-size: 14px;
  }
}

.bgt-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  display: inline-block;
  min-width: 280px;
  padding: 16px 45px 16px 20px;
  background: #EEF6FE;
  border: 1px solid #EEF6FE;
  border-radius: 50px;
  color: #174F9E;
  font-family: kinuta-maruminkatura-stdn, serif;
  font-size: 20px;
  line-height: 1.2;
  text-decoration: none;
  vertical-align: middle;
  text-decoration: none !important;
  transition: .5s;
}

@media screen and (max-width: 760px) {
  .bgt-btn {
    width: 100%;
    padding: 12px 40px 12px 20px;
    font-size: 15px;
  }
}

.bgt-btn::after {
  position: absolute;
  right: 25px;
  top: 50%;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 10px;
  border-color: transparent transparent transparent #174F9E;
  transform: translateY(-50%);
  transition: .5s;
}

@media screen and (max-width: 760px) {
  .bgt-btn::after {
    right: 20px;
    border-width: 5px 0 5px 8px;
  }
}

@media screen and (max-width: 760px) {
  [data-bgb=button] [data-bgt=button],
  [data-bgb=button2] [data-bgt=button],
  [data-bgb=button3] [data-bgt=button] {
    display: block;
  }
}

.bgt-btn:active, .bgt-btn:focus {
  -webkit-box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
  box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
}

.bgt-btn--link, [data-bgt-button-kind=link] .bgt-btn {
  color: #174F9E;
  border-color: #EEF6FE;
  background: #EEF6FE;
}

@media screen and (min-width: 761px) {
  .bgt-btn--link:active, .bgt-btn--link:focus, .bgt-btn--link:hover, [data-bgt-button-kind=link] .bgt-btn:active, [data-bgt-button-kind=link] .bgt-btn:focus, [data-bgt-button-kind=link] .bgt-btn:hover {
    background: #FFF9BD;
    border-color: #FFF9BD;
  }
}

.bgt-btn--em, [data-bgt-button-kind=em] .bgt-btn {
  color: #174F9E;
  border-color: #FFEC00;
  background: #FFEC00;
}

@media screen and (min-width: 761px) {
  .bgt-btn--em:active, .bgt-btn--em:focus, .bgt-btn--em:hover, [data-bgt-button-kind=em] .bgt-btn:active, [data-bgt-button-kind=em] .bgt-btn:focus, [data-bgt-button-kind=em] .bgt-btn:hover {
    border-color: #174F9E;
    background: #174F9E;
    color: #fff;
  }
}

@media screen and (min-width: 761px) {
  .bgt-btn--em:active::after, .bgt-btn--em:focus::after, .bgt-btn--em:hover::after, [data-bgt-button-kind=em] .bgt-btn:active::after, [data-bgt-button-kind=em] .bgt-btn:focus::after, [data-bgt-button-kind=em] .bgt-btn:hover::after {
    border-color: transparent transparent transparent #fff;
  }
}

.bgt-btn--wh, [data-bgt-button-kind=wh] .bgt-btn {
  color: #174F9E;
  border-color: #174F9E;
  background: #fff;
}

@media screen and (min-width: 761px) {
  .bgt-btn--wh:active, .bgt-btn--wh:focus, .bgt-btn--wh:hover, [data-bgt-button-kind=wh] .bgt-btn:active, [data-bgt-button-kind=wh] .bgt-btn:focus, [data-bgt-button-kind=wh] .bgt-btn:hover {
    border-color: #fff;
    background: linear-gradient(-90deg, #cde2f7 0%, #e4f9fa 100%);
    color: #174F9E;
  }
}

@media screen and (min-width: 761px) {
  .bgt-btn--wh:active::after, .bgt-btn--wh:focus::after, .bgt-btn--wh:hover::after, [data-bgt-button-kind=wh] .bgt-btn:active::after, [data-bgt-button-kind=wh] .bgt-btn:focus::after, [data-bgt-button-kind=wh] .bgt-btn:hover::after {
    border-color: transparent transparent transparent #174F9E;
  }
}

.bgt-btn--bl, [data-bgt-button-kind=bl] .bgt-btn {
  color: #fff;
  border-color: #174F9E;
  background: linear-gradient(-90deg, #2d3a80 0%, #375dd0 100%);
}

.bgt-btn--bl::after, [data-bgt-button-kind=bl] .bgt-btn::after {
  border-color: transparent transparent transparent #fff;
}

@media screen and (min-width: 761px) {
  .bgt-btn--bl:active, .bgt-btn--bl:focus, .bgt-btn--bl:hover, [data-bgt-button-kind=bl] .bgt-btn:active, [data-bgt-button-kind=bl] .bgt-btn:focus, [data-bgt-button-kind=bl] .bgt-btn:hover {
    border-color: #174F9E;
    background: #fff;
    color: #174F9E;
  }
}

@media screen and (min-width: 761px) {
  .bgt-btn--bl:active::after, .bgt-btn--bl:focus::after, .bgt-btn--bl:hover::after, [data-bgt-button-kind=bl] .bgt-btn:active::after, [data-bgt-button-kind=bl] .bgt-btn:focus::after, [data-bgt-button-kind=bl] .bgt-btn:hover::after {
    border-color: transparent transparent transparent #174F9E;
  }
}

.bgt-btn--external, [data-bgt-button-kind=external] .bgt-btn {
  color: #174F9E;
  border-color: #174F9E;
  background: #fff;
  transition: .5s;
}

@media screen and (min-width: 761px) {
  .bgt-btn--external:active, .bgt-btn--external:focus, .bgt-btn--external:hover, [data-bgt-button-kind=external] .bgt-btn:active, [data-bgt-button-kind=external] .bgt-btn:focus, [data-bgt-button-kind=external] .bgt-btn:hover {
    border-color: #EEF6FE;
    background: linear-gradient(-90deg, #cde2f7 0%, #e4f9fa 100%);
  }
}

.bgt-btn--external:after, [data-bgt-button-kind=external] .bgt-btn:after {
  position: static;
  display: inline-block;
  font-family: FontAwesome, serif;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f08e";
  font-size: 1em;
  color: #174F9E;
  vertical-align: middle;
  border: none;
  transform: translateY(-13px);
}

.bgt-btn--back, [data-bgt-button-kind=back] .bgt-btn {
  color: #333;
  border-color: #ddd;
  background: #ddd;
}

@media screen and (min-width: 761px) {
  .bgt-btn--back:active, .bgt-btn--back:focus, .bgt-btn--back:hover, [data-bgt-button-kind=back] .bgt-btn:active, [data-bgt-button-kind=back] .bgt-btn:focus, [data-bgt-button-kind=back] .bgt-btn:hover {
    background: #e9e9e9;
  }
}

.bgt-btn--back span, [data-bgt-button-kind=back] .bgt-btn span {
  display: inline-block;
  vertical-align: middle;
}

.bgt-btn--back:before, [data-bgt-button-kind=back] .bgt-btn:before {
  font-family: FontAwesome, serif;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f053";
  font-size: 1em;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
}

.bgt-btn--jb, [data-bgt-button-kind=jb] .bgt-btn {
  border-width: 3px;
  border-color: #2098CE;
  background: #2098CE;
  color: #fff;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 700;
}

.bgt-btn--jb::after, [data-bgt-button-kind=jb] .bgt-btn::after {
  border-color: transparent transparent transparent #fff;
}

@media screen and (min-width: 761px) {
  .bgt-btn--jb:active, .bgt-btn--jb:focus, .bgt-btn--jb:hover, [data-bgt-button-kind=jb] .bgt-btn:active, [data-bgt-button-kind=jb] .bgt-btn:focus, [data-bgt-button-kind=jb] .bgt-btn:hover {
    background: rgba(32, 152, 206, 0.1);
    color: #2098CE;
  }
}

@media screen and (min-width: 761px) {
  .bgt-btn--jb:active::after, .bgt-btn--jb:focus::after, .bgt-btn--jb:hover::after, [data-bgt-button-kind=jb] .bgt-btn:active::after, [data-bgt-button-kind=jb] .bgt-btn:focus::after, [data-bgt-button-kind=jb] .bgt-btn:hover::after {
    border-color: transparent transparent transparent #2098CE;
  }
}

.bgt-btn--jr, [data-bgt-button-kind=jr] .bgt-btn {
  border-width: 3px;
  border-color: #E65667;
  background: #E65667;
  color: #fff;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 700;
}

.bgt-btn--jr::after, [data-bgt-button-kind=jr] .bgt-btn::after {
  border-color: transparent transparent transparent #fff;
}

@media screen and (min-width: 761px) {
  .bgt-btn--jr:active, .bgt-btn--jr:focus, .bgt-btn--jr:hover, [data-bgt-button-kind=jr] .bgt-btn:active, [data-bgt-button-kind=jr] .bgt-btn:focus, [data-bgt-button-kind=jr] .bgt-btn:hover {
    background: rgba(230, 86, 103, 0.1);
    color: #E65667;
  }
}

@media screen and (min-width: 761px) {
  .bgt-btn--jr:active::after, .bgt-btn--jr:focus::after, .bgt-btn--jr:hover::after, [data-bgt-button-kind=jr] .bgt-btn:active::after, [data-bgt-button-kind=jr] .bgt-btn:focus::after, [data-bgt-button-kind=jr] .bgt-btn:hover::after {
    border-color: transparent transparent transparent #E65667;
  }
}

.bgt-btn--jy, [data-bgt-button-kind=jy] .bgt-btn {
  border-width: 3px;
  border-color: #FFC347;
  background: #FFC347;
  color: #fff;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 700;
}

.bgt-btn--jy::after, [data-bgt-button-kind=jy] .bgt-btn::after {
  border-color: transparent transparent transparent #fff;
}

@media screen and (min-width: 761px) {
  .bgt-btn--jy:active, .bgt-btn--jy:focus, .bgt-btn--jy:hover, [data-bgt-button-kind=jy] .bgt-btn:active, [data-bgt-button-kind=jy] .bgt-btn:focus, [data-bgt-button-kind=jy] .bgt-btn:hover {
    background: rgba(255, 195, 71, 0.1);
    color: #FFC347;
  }
}

@media screen and (min-width: 761px) {
  .bgt-btn--jy:active::after, .bgt-btn--jy:focus::after, .bgt-btn--jy:hover::after, [data-bgt-button-kind=jy] .bgt-btn:active::after, [data-bgt-button-kind=jy] .bgt-btn:focus::after, [data-bgt-button-kind=jy] .bgt-btn:hover::after {
    border-color: transparent transparent transparent #FFC347;
  }
}

.bgt-hr {
  margin: 0 auto;
}

[data-bgt-hr-kind=dashed] .bgt-hr {
  height: 0;
  background: none;
  border: none;
  border-bottom: 2px dashed;
}

.bgt-hr--bold, [data-bgt-hr-kind=bold] .bgt-hr {
  height: 5px;
}

.bgt-hr--narrow, [data-bgt-hr-kind=narrow] .bgt-hr {
  height: 1px;
}

.bgt-hr--short, [data-bgt-hr-kind=short] .bgt-hr {
  width: 60%;
  height: 1px;
}

.bgt-download-file__link {
  color: #174F9E;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding: .7em 1em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  text-decoration: underline;
}

@media screen and (max-width: 760px) {
  .bgt-download-file__link {
    font-size: 14px;
  }
}

.bgt-download-file__link .bgt-link__size {
  display: none;
}

.bgt-download-file__link-box {
  margin: 16px 0 8px;
  border: 1px solid #ccc;
  text-decoration: none !important;
}

.bgt-download-file__link-box:hover {
  background: #EEF6FE;
}

.bgt-download-file__link:hover {
  color: #F07700;
}

.bgt-download-file__link:active, .bgt-download-file__link:focus {
  -webkit-box-shadow: 0 0 5px 1px #0570c7;
  box-shadow: 0 0 5px 1px #0570c7;
  outline: none;
}

.bgt-link__size {
  color: grey;
  font-size: .8em;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .bgt-google-maps-link {
    margin-top: 10px;
    padding: 1em;
    text-decoration: none;
    color: #333;
    border: 1px solid #999;
    border-radius: 3px;
    text-align: center;
  }
  .bgt-google-maps-link span {
    display: inline-block;
    vertical-align: middle;
  }
  .bgt-google-maps-link:after {
    font-family: FontAwesome, serif;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f08e";
    font-size: 1em;
    vertical-align: middle;
  }
}

[data-bgb=jyuken-title] {
  text-align: center;
}

[data-bgb=jyuken-title] h2 {
  position: relative;
  display: inline-block;
  min-width: 500px;
  margin: 10px -20px;
  padding: 15px;
  border: solid 3px #2098CE;
  border-radius: 3px;
  background: #fff;
  color: #2098CE;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb=jyuken-title] h2 {
    min-width: auto;
    padding: 10px 15px;
    font-size: 17px;
  }
}

[data-bgb=jyuken-title] h2::after, [data-bgb=jyuken-title] h2::before {
  content: '';
  position: absolute;
  bottom: -7px;
  background: #2098CE;
  border: solid 2px #2098CE;
  width: 25px;
  height: 20px;
  z-index: -1;
}

[data-bgb=jyuken-title] h2::after {
  left: 2px;
  transform: rotate(120deg);
}

[data-bgb=jyuken-title] h2::before {
  right: 2px;
  transform: rotate(60deg);
}

[data-bgb=jyuken-title].jyuken-red h2 {
  border-color: #E65667;
  color: #E65667;
}

[data-bgb=jyuken-title].jyuken-red h2::before, [data-bgb=jyuken-title].jyuken-red h2::after {
  border-color: #E65667;
  background-color: #E65667;
}

[data-bgb=jyuken-title].jyuken-yellow h2 {
  border-color: #FFC347;
  color: #FFC347;
}

[data-bgb=jyuken-title].jyuken-yellow h2::before, [data-bgb=jyuken-title].jyuken-yellow h2::after {
  border-color: #FFC347;
  background-color: #FFC347;
}

[data-bgb=jyuken-title2] {
  text-align: center;
}

[data-bgb=jyuken-title2] h2 {
  padding: 8px;
  border-top: 2px solid #2098CE;
  border-bottom: 2px solid #2098CE;
  color: #2098CE;
  font-size: 24px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  [data-bgb=jyuken-title2] h2 {
    padding: 5px;
    font-size: 15px;
  }
}

[data-bgb=jyuken-title2].jyuken-red h2 {
  border-color: #E65667;
  color: #E65667;
}

[data-bgb=jyuken-title2].jyuken-yellow h2 {
  border-color: #FFC347;
  color: #FFC347;
}

[data-bgb=jyuken-title3] h3 {
  padding: 10px 25px;
  background-image: repeating-linear-gradient(135deg, transparent, transparent 4px, rgba(32, 152, 206, 0.1) 4px, rgba(32, 152, 206, 0.1) 10px);
  color: #2098CE;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  [data-bgb=jyuken-title3] h3 {
    padding: 8px 20px;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(32, 152, 206, 0.1) 3px, rgba(32, 152, 206, 0.1) 7px);
    font-size: 15px;
  }
}

[data-bgb=jyuken-title3].jyuken-red h3 {
  background-image: repeating-linear-gradient(135deg, transparent, transparent 4px, rgba(230, 86, 103, 0.1) 4px, rgba(230, 86, 103, 0.1) 10px);
  color: #E65667;
}

@media screen and (max-width: 760px) {
  [data-bgb=jyuken-title3].jyuken-red h3 {
    background-image: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(230, 86, 103, 0.1) 3px, rgba(230, 86, 103, 0.1) 7px);
  }
}

[data-bgb=jyuken-title3].jyuken-yellow h3 {
  background-image: repeating-linear-gradient(135deg, transparent, transparent 4px, rgba(255, 195, 71, 0.1) 4px, rgba(255, 195, 71, 0.1) 10px);
  color: #FFC347;
}

@media screen and (max-width: 760px) {
  [data-bgb=jyuken-title3].jyuken-yellow h3 {
    background-image: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255, 195, 71, 0.1) 3px, rgba(255, 195, 71, 0.1) 7px);
  }
}

[data-bgb=jyuken-title4] h4 {
  color: #2098CE;
}

[data-bgb=jyuken-title4] h4::before, [data-bgb=jyuken-title4] h4::after {
  background-color: #2098CE;
}

[data-bgb=jyuken-title4].jyuken-red h4 {
  color: #E65667;
}

[data-bgb=jyuken-title4].jyuken-red h4::before, [data-bgb=jyuken-title4].jyuken-red h4::after {
  background-color: #E65667;
}

[data-bgb=jyuken-title4].jyuken-yellow h4 {
  color: #FFC347;
}

[data-bgb=jyuken-title4].jyuken-yellow h4::before, [data-bgb=jyuken-title4].jyuken-yellow h4::after {
  background-color: #FFC347;
}

[data-bgb=jyuken-button] a.bgt-btn {
  border-width: 3px;
  border-color: #2098CE;
  background: #2098CE;
  color: #fff;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 700;
}

[data-bgb=jyuken-button] a.bgt-btn::after {
  border-color: transparent transparent transparent #fff;
}

@media screen and (min-width: 761px) {
  [data-bgb=jyuken-button] a.bgt-btn:hover {
    border-color: #2098CE;
    background: rgba(32, 152, 206, 0.1);
    color: #2098CE;
  }
}

@media screen and (min-width: 761px) {
  [data-bgb=jyuken-button] a.bgt-btn:hover::after {
    border-color: transparent transparent transparent #2098CE;
  }
}

[data-bgb=jyuken-button].jyuken-red a.bgt-btn {
  border-width: 3px;
  border-color: #E65667;
  background: #E65667;
  color: #fff;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 700;
}

[data-bgb=jyuken-button].jyuken-red a.bgt-btn::after {
  border-color: transparent transparent transparent #fff;
}

@media screen and (min-width: 761px) {
  [data-bgb=jyuken-button].jyuken-red a.bgt-btn:hover {
    background: rgba(230, 86, 103, 0.1);
    color: #E65667;
  }
}

@media screen and (min-width: 761px) {
  [data-bgb=jyuken-button].jyuken-red a.bgt-btn:hover::after {
    border-color: transparent transparent transparent #E65667;
  }
}

[data-bgb=jyuken-button].jyuken-yellow a.bgt-btn {
  border-width: 3px;
  border-color: #FFC347;
  background: #FFC347;
  color: #fff;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 700;
}

[data-bgb=jyuken-button].jyuken-yellow a.bgt-btn::after {
  border-color: transparent transparent transparent #fff;
}

@media screen and (min-width: 761px) {
  [data-bgb=jyuken-button].jyuken-yellow a.bgt-btn:hover {
    background: rgba(255, 195, 71, 0.1);
    color: #FFC347;
  }
}

@media screen and (min-width: 761px) {
  [data-bgb=jyuken-button].jyuken-yellow a.bgt-btn:hover::after {
    border-color: transparent transparent transparent #FFC347;
  }
}

.bge-contents {
  padding-bottom: 60px;
}

@media screen and (max-width: 760px) {
  .bge-contents {
    padding-bottom: 30px;
    overflow: hidden;
  }
}

.bge-contents [data-bgb] {
  margin: 0 0 3em;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=title], .bge-contents [data-bgb=title2] {
    margin: 0 0 1.5em;
  }
}

.bge-contents [data-bgb]:not(.bge-wide) {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb]:not(.bge-wide) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.bge-contents [data-bgb].bge-wide {
  padding: 50px 0;
  background: #EEF6FE;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb].bge-wide {
    padding: 25px 0;
  }
}

.bge-contents [data-bgb].bge-wide.cream {
  background: #FFFDEA;
}

.bge-contents [data-bgb].bge-wide.gray {
  background: #F4F5F2;
}

.bge-contents [data-bgb].bge-wide [data-bgt] {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb].bge-wide [data-bgt] {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.bge-contents [data-bgb].bge-wide [data-bgt] h2 {
  margin: 0 0 60px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb].bge-wide [data-bgt] h2 {
    margin: 0 0 30px;
  }
}

.bge-contents [data-bgb].bge-blue {
  max-width: 1130px;
  padding: 2em 3em;
  background: #EEF6FE;
}

.bge-contents [data-bgb].bg-jyuken-blue {
  max-width: 1130px;
  padding: 2em 3em;
  background: rgba(32, 152, 206, 0.1);
}

.bge-contents [data-bgb].bg-jyuken-red {
  max-width: 1130px;
  padding: 2em 3em;
  background: rgba(230, 86, 103, 0.1);
}

.bge-contents [data-bgb].bg-jyuken-yellow {
  max-width: 1130px;
  padding: 2em 3em;
  background: rgba(255, 195, 71, 0.1);
}

.bge-contents [data-bgb].bg-notice {
  max-width: 1130px;
  padding: 2em 3em;
  background: #E65667;
  color: #fff;
  -webkit-font-smoothing: subpixel-antialiased;
}

.bge-contents [data-bgb].bge-list {
  border-top: 1px solid #ccc;
  padding-top: 16px;
  padding-bottom: 16px;
}

.bge-contents [data-bgb].bge-list:last-child, .bge-contents [data-bgb].bge-list.last {
  border-bottom: 1px solid #ccc;
}

.bge-contents [data-bgb].bge-list p {
  margin: 0;
  line-height: 1.5;
}

.bge-contents [data-bgb].bge-spwide {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb].bge-spwide {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.bge-contents [data-bgb].bge-imgwide img {
  width: 100%;
}

.bge-contents [data-bgb=wysiwyg2] h2, .bge-contents [data-bgb=wysiwyg] h2,
.bge-contents [data-bgb=wysiwyg2] h3, .bge-contents [data-bgb=wysiwyg] h3 {
  margin-bottom: 1em;
}

.bge-contents [data-bgb] a[href] img {
  transition: .5s;
}

.bge-contents [data-bgb] a[href]:hover img {
  opacity: .7;
}

.bge-contents [data-bgb=image-text2] [data-bgt=image],
.bge-contents [data-bgb=image-text3] [data-bgt=image],
.bge-contents [data-bgb=image-text4] [data-bgt=image],
.bge-contents [data-bgb=image-text5] [data-bgt=image],
.bge-contents [data-bgb=image-link-text2] [data-bgt=image-link],
.bge-contents [data-bgb=image-link-text3] [data-bgt=image-link],
.bge-contents [data-bgb=image-link-text4] [data-bgt=image-link],
.bge-contents [data-bgb=image-link-text5] [data-bgt=image-link] {
  margin-bottom: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=image-text2] .bgt-grid,
  .bge-contents [data-bgb=image-text3] .bgt-grid,
  .bge-contents [data-bgb=image-text4] .bgt-grid,
  .bge-contents [data-bgb=image-text5] .bgt-grid {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=image-text2] .bgt-grid--last,
  .bge-contents [data-bgb=image-text3] .bgt-grid--last,
  .bge-contents [data-bgb=image-text4] .bgt-grid--last,
  .bge-contents [data-bgb=image-text5] .bgt-grid--last {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=text-image1] .bgt-grid--first,
  .bge-contents [data-bgb=text-image2] .bgt-grid--last {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=text-image1].img-reverse .bgt-grid--first,
  .bge-contents [data-bgb=text-image2].img-reverse .bgt-grid--last {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=text-image1].img-reverse .bgt-grid--last,
  .bge-contents [data-bgb=text-image2].img-reverse .bgt-grid--first {
    margin-bottom: 20px;
  }
}

.bge-contents [data-bgb=text-image1] .bgt-grid--first {
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=text-image1] .bgt-grid--first {
    padding-right: 0;
  }
}

.bge-contents [data-bgb=text-image1] .bgt-grid--last {
  padding-left: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=text-image1] .bgt-grid--last {
    padding-left: 0;
  }
}

.bge-contents [data-bgb=text-image2] .bgt-grid--first {
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=text-image2] .bgt-grid--first {
    padding-right: 0;
  }
}

.bge-contents [data-bgb=text-image2] .bgt-grid--last {
  padding-left: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=text-image2] .bgt-grid--last {
    padding-left: 0;
  }
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=text-image2] {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=text-image1].img-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=text-image2].img-reverse {
    display: flex;
    flex-direction: column;
  }
}

.bge-contents .bgt-download-file__link[href] .bgt-link__icon {
  margin-right: 10px;
}

.bge-contents .bgt-download-file__link[href] .bgt-link__icon:after {
  display: inline-block;
  content: "\f0f6";
  transform: translateY(-3px);
}

.bge-contents a.dl {
  display: inline-block;
  color: #174F9E;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding: .7em 1em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
  text-decoration: underline;
}

@media screen and (max-width: 760px) {
  .bge-contents a.dl {
    font-size: 14px;
  }
}

.bge-contents a.dl i {
  margin-right: 10px;
}

.bge-contents a.dl i::after {
  display: inline-block;
  content: "\f0f6";
  font-size: 1.3em;
  transform: translateY(-4px);
  font-family: FontAwesome,serif;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: bottom;
}

.bge-contents a.dl.box {
  margin: 16px 0 8px;
  border: 1px solid #ccc;
  text-decoration: none !important;
}

.bge-contents a.dl.box:hover {
  background: #EEF6FE;
}

.bge-contents [data-bgb=wysiwyg2] {
  display: flex;
  float: none;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=wysiwyg2] {
    display: block;
  }
}

.bge-contents [data-bgb=wysiwyg2] .bgt-grid {
  width: 49%;
  margin-right: 2%;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=wysiwyg2] .bgt-grid {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.bge-contents [data-bgb=wysiwyg2] .bgt-grid--last {
  margin-right: 0;
}

.bge-contents [data-bgb=wysiwyg3] {
  display: flex;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=wysiwyg3] {
    display: block;
  }
}

.bge-contents [data-bgb=wysiwyg3] .bgt-grid {
  width: 32%;
  margin-right: 2%;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=wysiwyg3] .bgt-grid {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.bge-contents [data-bgb=wysiwyg3] .bgt-grid:nth-child(3n) {
  margin-right: 0;
}

.bge-contents [data-bgb=wysiwyg3] .bgt-grid--last {
  margin-right: 0;
}

.bge-contents [data-bgb=wysiwyg].bge-border .bgt-container,
.bge-contents [data-bgb].bge-border .bgt-grid {
  padding: 20px;
  border: 1px solid #ccc;
}

.bge-contents [data-bgb].bge-border-02 .bgt-grid--last {
  border: 0;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb].bge-border-02 .bgt-grid--last {
    display: none;
  }
}

.bge-contents [data-bgb].bge-border-01 .bgt-grid:not(:first-child) {
  border: 0;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb].bge-border-01 .bgt-grid:not(:first-child) {
    display: none;
  }
}

.bge-contents [data-bgb=wysiwyg].bge-border.clr-bl .bgt-container,
.bge-contents [data-bgb].bge-border.clr-bl .bgt-grid {
  border-color: #174F9E;
}

.bge-contents [data-bgb=wysiwyg].bd-jyuken-blue .bgt-container,
.bge-contents [data-bgb].bd-jyuken-blue .bgt-grid {
  padding: 20px;
  border: 3px solid #2098CE;
}

.bge-contents [data-bgb=wysiwyg].bd-jyuken-red .bgt-container,
.bge-contents [data-bgb].bd-jyuken-red .bgt-grid {
  padding: 20px;
  border: 3px solid #E65667;
}

.bge-contents [data-bgb=wysiwyg].bd-jyuken-yellow .bgt-container,
.bge-contents [data-bgb].bd-jyuken-yellow .bgt-grid {
  padding: 20px;
  border: 3px solid #FFC347;
}

.bge-contents [data-bgb] a[href]:not(.bgt-btn) {
  color: #174F9E;
}

.bge-contents .page-link .bgt-btn::after {
  border-width: 10px 6px 0 6px;
  border-color: #174F9E transparent transparent transparent;
}

@media screen and (max-width: 760px) {
  .bge-contents .page-link .bgt-btn::after {
    border-width: 8px 5px 0 5px;
  }
}

.bge-contents .btn-box a {
  position: relative;
  display: inline-block;
  min-width: 280px;
  padding: 16px 45px 16px 20px;
  background: #EEF6FE;
  border: 1px solid #EEF6FE;
  border-radius: 50px;
  color: #174F9E;
  font-family: kinuta-maruminkatura-stdn, serif;
  font-size: 20px;
  line-height: 1.2;
  text-decoration: none;
  vertical-align: middle;
  text-decoration: none !important;
  transition: .5s;
}

@media screen and (max-width: 760px) {
  .bge-contents .btn-box a {
    width: 100%;
    padding: 12px 40px 12px 20px;
    font-size: 15px;
  }
}

.bge-contents .btn-box a::after {
  position: absolute;
  right: 25px;
  top: 50%;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 10px;
  border-color: transparent transparent transparent #174F9E;
  transform: translateY(-50%);
  transition: .5s;
}

@media screen and (max-width: 760px) {
  .bge-contents .btn-box a::after {
    right: 20px;
    border-width: 5px 0 5px 8px;
  }
}

.bge-contents .ttl-caption {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}

.bge-contents .ttl-caption::before {
  content: "■ ";
  color: #174F9E;
}

@media screen and (max-width: 760px) {
  .bge-contents .ttl-caption {
    font-size: 16px;
  }
}

.bge-contents [data-bgb=faq] .faq_list_q {
  position: relative;
  margin: 0;
  padding: 24px 30px 24px 90px;
  background: #EEF6FE;
  color: #174F9E;
  font-size: 22px;
  line-height: 1.3636;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=faq] .faq_list_q {
    padding: 18px 20px 18px 56px;
    font-size: 15px;
  }
}

.bge-contents [data-bgb=faq] .faq_list_q::before {
  position: absolute;
  left: 30px;
  top: 25px;
  content: "";
  width: 32px;
  height: 32px;
  background: #174F9E;
  transform: rotate(45deg);
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=faq] .faq_list_q::before {
    left: 14px;
    top: 19px;
    width: 26px;
    height: 26px;
  }
}

.bge-contents [data-bgb=faq] .faq_list_q::after {
  position: absolute;
  left: 38px;
  top: 24px;
  content: "Q";
  display: block;
  color: #fff;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=faq] .faq_list_q::after {
    left: 21px;
    top: 19px;
  }
}

.bge-contents [data-bgb=faq] .faq_list_a {
  position: relative;
  margin: 0;
  padding: 30px 30px 0 90px;
  line-height: 1.875;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=faq] .faq_list_a {
    padding: 15px 0 0;
    font-size: 13px;
  }
}

.bge-contents [data-bgb=download-file] {
  text-align: left;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=download-file2],
  .bge-contents [data-bgb=download-file3] {
    text-align: left;
  }
}

.bge-contents [data-bgb=youtube2] {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=youtube2] {
    display: block;
    width: auto;
  }
}

@media screen and (min-width: 761px) {
  .bge-contents [data-bgb=youtube2] .bgt-grid--first {
    padding-right: 20px;
  }
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=youtube2] .bgt-grid--first {
    margin-bottom: 3em;
  }
}

@media screen and (min-width: 761px) {
  .bge-contents [data-bgb=youtube2] .bgt-grid--last {
    padding-left: 20px;
  }
}

.bge-contents [data-bgb=youtube2-wysiwyg] {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=youtube2-wysiwyg] {
    display: block;
    width: auto;
  }
}

@media screen and (min-width: 761px) {
  .bge-contents [data-bgb=youtube2-wysiwyg] .bgt-grid--first {
    padding-right: 20px;
  }
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=youtube2-wysiwyg] .bgt-grid--first {
    margin-bottom: 3em;
  }
}

@media screen and (min-width: 761px) {
  .bge-contents [data-bgb=youtube2-wysiwyg] .bgt-grid--last {
    padding-left: 20px;
  }
}

.bge-contents [data-bgb=youtube2-wysiwyg] [data-bgt=youtube] {
  margin-bottom: 10px;
}

.bge-contents [data-bgb=text-image-text2] {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=text-image-text2] {
    display: block;
    width: auto;
  }
}

@media screen and (min-width: 761px) {
  .bge-contents [data-bgb=text-image-text2] .bgt-grid--first {
    padding-right: 20px;
  }
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=text-image-text2] .bgt-grid--first {
    margin-bottom: 3em;
  }
}

@media screen and (min-width: 761px) {
  .bge-contents [data-bgb=text-image-text2] .bgt-grid--last {
    padding-left: 20px;
  }
}

.bge-contents [data-bgb=text-image-text2] [data-bgt=image] {
  margin-bottom: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=text-image-text2] [data-bgt=image] {
    margin-bottom: 10px;
  }
}

.bge-contents [data-bgb=text-image-text2] [data-bgt=image] img {
  width: 100%;
}

.bge-contents [data-bgb=course-student],
.bge-contents [data-bgb=course-student].bge-normal1 {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: relative;
  max-width: 1130px !important;
  padding-top: 30px;
  background: #E5F2F9;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=course-student],
  .bge-contents [data-bgb=course-student].bge-normal1 {
    display: block;
    width: auto;
  }
}

.bge-contents [data-bgb=course-student]::before,
.bge-contents [data-bgb=course-student].bge-normal1::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 40px;
  background: #fff url(/img/course/bg_message_01.png) top left no-repeat;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=course-student]::before,
  .bge-contents [data-bgb=course-student].bge-normal1::before {
    background: #fff url(/img/course/sp/bg_message_01.png) top left no-repeat;
    background-size: 100% auto;
  }
}

.bge-contents [data-bgb=course-student] .bgt-grid--first,
.bge-contents [data-bgb=course-student].bge-normal1 .bgt-grid--first {
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=course-student] .bgt-grid--first,
  .bge-contents [data-bgb=course-student].bge-normal1 .bgt-grid--first {
    padding-right: 0;
  }
}

.bge-contents [data-bgb=course-student] .bgt-grid--last,
.bge-contents [data-bgb=course-student].bge-normal1 .bgt-grid--last {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 40px;
  padding-left: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=course-student] .bgt-grid--last,
  .bge-contents [data-bgb=course-student].bge-normal1 .bgt-grid--last {
    padding-left: 0;
    padding-right: 0;
  }
}

.bge-contents [data-bgb=course-student] .ttl-c-message,
.bge-contents [data-bgb=course-student].bge-normal1 .ttl-c-message {
  color: #007FC7;
  font-size: 26px;
  font-weight: 700;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=course-student] .ttl-c-message,
  .bge-contents [data-bgb=course-student].bge-normal1 .ttl-c-message {
    font-size: 18px;
  }
}

.bge-contents [data-bgb=course-student] .text-c-message,
.bge-contents [data-bgb=course-student].bge-normal1 .text-c-message {
  margin-bottom: 40px;
}

.bge-contents [data-bgb=course-student] .box-c-message,
.bge-contents [data-bgb=course-student].bge-normal1 .box-c-message {
  position: relative;
  padding: 50px 36px 24px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background: #fff;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=course-student] .box-c-message,
  .bge-contents [data-bgb=course-student].bge-normal1 .box-c-message {
    padding: 40px 24px 20px;
  }
}

.bge-contents [data-bgb=course-student] .box-c-message::before,
.bge-contents [data-bgb=course-student].bge-normal1 .box-c-message::before {
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  display: block;
  width: 195px;
  height: 53px;
  background: url(/img/course/ttl_message_s.png) 0 0 no-repeat;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=course-student] .box-c-message::before,
  .bge-contents [data-bgb=course-student].bge-normal1 .box-c-message::before {
    width: 170px;
    height: 46px;
    background-size: 170px auto;
  }
}

.bge-contents [data-bgb=course-student].bge-normal2 {
  background: #E5F5F7;
}

.bge-contents [data-bgb=course-student].bge-normal2::before {
  background: #fff url(/img/course/bg_message_02.png) top left no-repeat;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=course-student].bge-normal2::before {
    background: #fff url(/img/course/sp/bg_message_02.png) top left no-repeat;
    background-size: 100% auto;
  }
}

.bge-contents [data-bgb=course-student].bge-normal2 .ttl-c-message {
  color: #00A0AE;
}

.bge-contents [data-bgb=course-student].bge-industry {
  background: #FEF5E5;
}

.bge-contents [data-bgb=course-student].bge-industry::before {
  background: #fff url(/img/course/bg_message_03.png) top left no-repeat;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=course-student].bge-industry::before {
    background: #fff url(/img/course/sp/bg_message_03.png) top left no-repeat;
    background-size: 100% auto;
  }
}

.bge-contents [data-bgb=course-student].bge-industry .ttl-c-message {
  color: #F07700;
}

.bge-contents [data-bgb=course-teacher] {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  max-width: 1130px !important;
  background: #FFFDEA;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=course-teacher] {
    display: block;
    width: auto;
  }
}

.bge-contents [data-bgb=course-teacher] .bgt-grid--first {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=course-teacher] .bgt-grid--first {
    padding-right: 0;
    padding-left: 0;
  }
}

.bge-contents [data-bgb=course-teacher] .bgt-grid--last {
  padding-left: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=course-teacher] .bgt-grid--last {
    padding-left: 0;
  }
}

.bge-contents [data-bgb=course-teacher] .ttl-c-message {
  position: relative;
  margin-bottom: 20px;
  padding-top: 100px;
  color: #174F9E;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.46;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=course-teacher] .ttl-c-message {
    margin-bottom: 10px;
    padding-top: 70px;
    font-size: 18px;
  }
}

.bge-contents [data-bgb=course-teacher] .ttl-c-message::before {
  position: absolute;
  top: 10px;
  left: 0;
  content: "";
  display: block;
  width: 242px;
  height: 80px;
  background: url(/img/course/ttl_message_t.png) 0 0 no-repeat;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=course-teacher] .ttl-c-message::before {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 170px;
    height: 56px;
    background-size: 170px auto;
  }
}

.bge-contents [data-bgb=course-teacher].bge-teacher-blue {
  flex-direction: row-reverse;
  background: #eef6fe;
}

.bge-contents [data-bgb=course-teacher].bge-teacher-blue .ttl-c-message::before {
  background-image: url(/img/course/ttl_message_t_bl.png);
}

.bge-contents [data-bgt=page-link] .bgt-pagelink {
  position: relative;
  display: flex;
  align-items: center;
  width: 22.65%;
  height: 70px;
  padding: 0 46px 0 22px;
  border: 1px solid #174F9E;
  color: #174F9E;
  font-family: kinuta-maruminkatura-stdn, serif;
  font-size: 19px;
  letter-spacing: 0;
  line-height: 1.2;
  text-decoration: none !important;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgt=page-link] .bgt-pagelink {
    width: 49%;
    height: 50px;
    padding: 0 24px 0 10px;
    font-size: 14px;
  }
}

.bge-contents [data-bgt=page-link] .bgt-pagelink::before {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #174F9E;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgt=page-link] .bgt-pagelink::before {
    right: 12px;
    width: 12px;
    height: 12px;
  }
}

.bge-contents [data-bgt=page-link] .bgt-pagelink::after {
  position: absolute;
  top: 50%;
  right: 26px;
  transform: translateY(-50%);
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 4px 0;
  border-color: #fff transparent transparent;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgt=page-link] .bgt-pagelink::after {
    right: 15px;
    border-width: 5px 3px 0;
  }
}

@media screen and (min-width: 761px) {
  .bge-contents [data-bgt=page-link] .bgt-pagelink:hover {
    border-color: #fff;
    background: linear-gradient(-90deg, #cde2f7 0, #e4f9fa 100%);
  }
}

.bge-contents [data-bgb=page-link2], .bge-contents [data-bgb=page-link3], .bge-contents [data-bgb=page-link4] {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.bge-contents [data-bgb=page-link2] .bgt-grid, .bge-contents [data-bgb=page-link3] .bgt-grid, .bge-contents [data-bgb=page-link4] .bgt-grid {
  width: 22.65%;
  margin-right: 3.1%;
  margin-bottom: 20px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=page-link2] .bgt-grid, .bge-contents [data-bgb=page-link3] .bgt-grid, .bge-contents [data-bgb=page-link4] .bgt-grid {
    width: 49%;
    margin-right: 2%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=page-link2] .bgt-grid:nth-child(2n), .bge-contents [data-bgb=page-link3] .bgt-grid:nth-child(2n), .bge-contents [data-bgb=page-link4] .bgt-grid:nth-child(2n) {
    margin-right: 0;
  }
}

.bge-contents [data-bgb=page-link2] .bgt-grid--last, .bge-contents [data-bgb=page-link3] .bgt-grid--last, .bge-contents [data-bgb=page-link4] .bgt-grid--last {
  margin-right: 0;
}

.bge-contents [data-bgb=page-link2] .bgt-pagelink, .bge-contents [data-bgb=page-link3] .bgt-pagelink, .bge-contents [data-bgb=page-link4] .bgt-pagelink {
  width: 100% !important;
}

.bge-contents [data-bgb=document] .doc-pdf {
  padding: 10px 15px 20px;
  border-top: 1px solid #174F9E;
}

.bge-contents [data-bgb=document]:nth-child(odd) .doc-pdf {
  background: #EEF6FE;
}

.bge-contents [data-bgb=document] .doc-pdf-info {
  margin-bottom: 10px;
}

.bge-contents [data-bgb=document] .doc-pdf-info * {
  display: inline-block;
}

.bge-contents [data-bgb=document] .doc-pdf-info dt {
  color: #444;
}

.bge-contents [data-bgb=document] .doc-pdf-info dd {
  margin-right: 30px;
  font-weight: 700;
  font-size: 17px;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=document] .doc-pdf-info dd {
    font-size: 15px;
  }
}

.bge-contents [data-bgb=document] .doc-pdf-dl a {
  padding: .3em 1em .5em;
  border: 1px solid #174F9E;
  background-color: #fff;
  text-decoration: none;
}

.bge-contents .club-slide {
  margin-top: 3em;
}

.bge-contents [data-bgb=image3-scroll] {
  margin-top: 3em;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=image3-scroll] {
    padding-bottom: 10px;
    overflow-x: scroll;
  }
}

.bge-contents [data-bgb=image3-scroll]::-webkit-scrollbar {
  height: 5px;
}

.bge-contents [data-bgb=image3-scroll]::-webkit-scrollbar-track {
  background: #EEF6FE;
}

.bge-contents [data-bgb=image3-scroll]::-webkit-scrollbar-thumb {
  background: #174F9E;
}

@media screen and (max-width: 760px) {
  .bge-contents [data-bgb=image3-scroll] .image3-scroll-inner {
    width: 800px;
    padding-right: 5vw;
  }
}

.bge-contents [data-bgb=image3-scroll] .bgt-grid {
  width: 33.3333%;
  float: left;
  padding-left: 6.66667px;
  padding-right: 6.66667px;
}

.bge-contents [data-bgb=image3-scroll] .bgt-grid:first-child {
  padding-left: 0;
  padding-right: 13.33333px;
}

.bge-contents [data-bgb=image3-scroll] .bgt-grid:nth-child(3) {
  padding-left: 13.33333px;
  padding-right: 0;
}

.bge_content [data-bgb].pc [data-bgt]::before {
  content: "　　パソコン版　　";
  background: yellow;
}

.bge_content [data-bgb].sp [data-bgt]::before {
  content: "　　スマホ版　　";
  background: yellow;
}

@media screen and (max-width: 760px) {
  [data-bgb].curriculum table:not(.table-scroll) {
    display: table;
  }
}

@media screen and (max-width: 760px) {
  [data-bgb].curriculum table:not(.table-scroll) tr {
    display: table-row;
  }
}

[data-bgb].curriculum table:not(.table-scroll) tr:first-child th, [data-bgb].curriculum table:not(.table-scroll) tr:first-child td {
  background: #EEF6FE;
}

[data-bgb].curriculum table:not(.table-scroll) tr th {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb].curriculum table:not(.table-scroll) tr th {
    display: table-cell;
    width: auto;
  }
}

[data-bgb].curriculum table:not(.table-scroll) tr th:first-child {
  background: #EEF6FE;
}

[data-bgb].curriculum table:not(.table-scroll) tr td {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
}

@media screen and (max-width: 760px) {
  [data-bgb].curriculum table:not(.table-scroll) tr td {
    display: table-cell;
  }
}

@media screen and (max-width: 760px) {
  [data-bgb].curriculum table:not(.table-scroll) tr td:not(:first-child) {
    width: 9999px;
  }
}

[data-bgb].curriculum table:not(.table-scroll) tr td:first-child {
  width: 60px;
  background: #EEF6FE;
}

.ob-box .ob-info {
  margin-bottom: 10px;
}

.ob-box .ob-info .ob-photo {
  float: left;
  width: 90px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.ob-box .ob-info .ob-text {
  margin-bottom: 10px;
}

.ob-box .ob-info .ob-text dt {
  margin-bottom: 10px;
  font-size: 15px;
  color: #174F9E;
  line-height: 1.4;
}

.ob-box .ob-info .ob-text dt span {
  font-size: 18px;
  font-weight: 700;
}

.ob-box .ob-info .ob-text dd {
  font-size: 13px;
  color: #999;
  line-height: 1.4;
}

.ob-box .ob-info .ob-text dd span {
  margin-right: 4px;
  font-size: 17px;
  font-weight: 700;
}

.ob-box .ob-copy {
  margin-top: 20px;
  color: #F07700;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
}

@media screen and (max-width: 760px) {
  .ob-box .ob-copy {
    clear: both;
    margin-top: 0;
  }
}

.ob-box .ob-message {
  clear: both;
  line-height: 1.6;
  font-size: 15px;
}

@media screen and (max-width: 760px) {
  .ob-box .ob-message {
    padding-top: 0;
  }
}

.ob-box .ob-message p {
  margin-bottom: 0;
}

.ob-box .ob-message strong {
  color: #174F9E;
}

[data-bgb=wysiwyg2].ob-box .bgt-grid [data-bgt] > :last-child {
  margin-bottom: 0 !important;
}

[data-bgb=wysiwyg2].ob-box .ob-info .ob-photo {
  width: 150px;
}

@media screen and (max-width: 760px) {
  [data-bgb=wysiwyg2].ob-box .ob-info .ob-photo {
    width: 90px;
  }
}

[data-bgb=wysiwyg2].ob-box .ob-info .ob-photo img {
  width: 100%;
}
